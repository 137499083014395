/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect}  from 'react';
// eslint-disable-next-line quotes
//import AppRouter, { history } from "../routes/AppRouter";
import { Snackbar, Typography, Box, CircularProgress } from '@mui/material';
import { editFilterData, setLoading } from '../../actions/filters';
import submitGetSheetId from '../service/submitGetSheetId';
import { useDispatch, useSelector } from 'react-redux';
import {RootState} from '../../store/ConfigureStore';
import { useNavigate } from "react-router-dom";
import { subHeaderStyles } from '../../styles/themeStylesSx';

const ProfilePage = () => {
  const [loading, setLoading1] = useState (false);
  const dispatch = useDispatch();
  const authorit: any = useSelector((state: RootState) => state.auth)

  const filters: any = useSelector((state: RootState) => state.filters);
  const token = `bearer ${authorit.idToken}`;
  const [errorMessage, setErrorMessage] = useState (false);
  let componentMounted = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [buttonStyle, setButtonStyle] = useState<"inherit" | "primary" | "secondary" | "default" | undefined>('primary');

  let navigate = useNavigate();
  useEffect (() => {
    // if (authorit.idToken) {
      if (filters.error) {
        setErrorMessage(filters.errorMessage);
      }

    if (!authorit.idToken) navigate("/", {replace:true});
    if (!filters.admin && !filters.read && !filters.write && !filters.drive && !filters.change && !filters.listCal && filters.freetime )
      navigate("/puhujat");
    if (!filters.admin && filters.read && filters.write && !filters.drive && filters.change && !filters.listCal && !filters.freetime )
      navigate("/seuraohjelma");

    const getSheetId = async () => {
      if ( authorit.idToken ) {
        const doDispatch = true;
        const sheetData = await submitGetSheetId(token,filters.serviceYear,dispatch, doDispatch, filters)
        //dispatch(setSheetData(sheetData))

        const currentSheet = ({
          sheetId: sheetData?.sheetId,
          range: sheetData?.range,
          columnSpeaker: sheetData?.columnSpeaker,
          columnAddInfo: sheetData?.columnAddInfo,
          subSheet: sheetData?.subSheet,
          speakerSubColumn: sheetData?.speakerSubColumn,
          speakerSubDayCol1: sheetData?.speakerSubDayCol1,
          speakerSubDayCol2: sheetData?.speakerSubDayCol1,
          subjectColumn:sheetData?.subjectColumn,
        });
        dispatch( editFilterData({currentSheet}));
      }
    }
    dispatch(setLoading(true))
    setLoading1(true);
    getSheetId()
//}
    if (componentMounted) {
      dispatch(setLoading(false))
      setLoading1(false);

    }
    return () => { // This code runs when component is unmounted
      // eslint-disable-next-line react-hooks/exhaustive-deps
      componentMounted = false; // (4) set it to false if we leave the page
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters.freetime,filters.admin, filters.read , filters.write , filters.drive, filters.change, filters.listCal, filters.freetime])

  if (!authorit.idToken) return <div>Kirjaudu järjestelmään</div>
  if (loading) <CircularProgress/>
 
  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(false);
  }

  return  (
    <Box>
       {
      (filters.error) &&
      <div>
        <Snackbar
          open={filters.error}
          autoHideDuration={10000}
          onClose={handleClose}
          message= {errorMessage}
          />
      </div>
     }

      <Box>
      <Box>
    <Typography sx={ subHeaderStyles }>{`Oikeudet Hry-portaliin :  ${authorit.email}`}</Typography>
    </Box>   
        <Box>
         {'Admin:' } {filters.admin === true ? 'Kyllä' : 'Ei'}
        </Box>
        <Box>
         {'Luku:' } {filters.admin === true ? 'Kyllä' : 'Ei'}
        </Box>
        <Box>
         {'Kirjoitus:' } {filters.admin === true ? 'Kyllä' : 'Ei'}
        </Box>
        <Box>
         {'Drive:' } {filters.admin === true ? 'Kyllä' : 'Ei'}
        </Box>
        <Box>
         {'Puhujien vapaat:' } {filters.admin === true ? 'Kyllä' : 'Ei'}
        </Box>
        <Box>
         {'Muutos:' } {filters.admin === true ? 'Kyllä' : 'Ei'}
        </Box>
        <Box>
         {'Kalenterin listaus:' } {filters.admin === true ? 'Kyllä' : 'Ei'}
        </Box>
      </Box>
    </Box>
  )
}
export default ProfilePage
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import { v4 as uuidv4 } from 'uuid';
import database from '../firebase/firebase';
export const editTimestamp = (lastChangeTimestamp) => ({
  type: 'EDIT_TIMESTAMP',
  lastChangeTimestamp
});
export const editFilterData = (filterData) => ({
  type: 'EDIT_FILTER_DATA',
  filterData
});
export const setTextFilter = (text = '') => ({
  type: 'SET_TEXT_FILTER',
  text
});
export const setDaysToState = (daysToState) => ({
  type: 'SET_DAYS_TO_STATE',
  daysToState
});
export const setTypesToState = (typesToState) => ({
  type: 'SET_TYPES_TO_STATE',
  typesToState
});
export const setStartDateRed = (startDate) => ({
  type: 'SET_START_DATE_RED',
  startDate
});
export const setEndDateRed = (endDate) => ({
  type: 'SET_END_DATE_RED',
  endDate
});
// SORT_BY_LINENUMBER
export const sortByLinenumber = () => ({
  type: 'SORT_BY_LINENUMBER'
});
  // SORT_BY_DATE
export const sortByDate = () => ({
  type: 'SORT_BY_DATE'
});
// SORT_BY_TYPE
export const sortByType = () => ({
  type: 'SORT_BY_TYPE'
});
export const sortByCreated = () => ({
  type: 'SORT_BY_CREATED'
});
export const sortByDrive = () => ({
  type: 'SORT_BY_DRIVE'
});
export const sortByLastLogin = () => ({
  type: 'SORT_BY_LAST_LOGIN'
});
export const sortByName = () => ({
  type: 'SORT_BY_NAME'
});
export const sortByRole = () => ({
  type: 'SORT_BY_ROLE'
});
// SORT_BY_SPEAKER
export const sortBySpeaker = () => ({
  type: 'SORT_BY_SPEAKER'
});
export const setStartDate = (startDate) => ({
  type: 'SET_START_DATE',
  startDate
});

export const setListAll = (listAll) => ({
  type: 'SET_LIST_ALL',
  listAll
});
export const haveToReadDrive = (haveToReadDrive) => ({
  type: 'HAVE_TO_READ_DRIVE',
  haveToReadDrive
});
export const setEndDate = (endDate) => ({
  type: 'SET_END_DATE',
  endDate
});
export const setServiceYear = (serviceYear) => ({
  type: 'SET_SERVICE_YEAR',
  serviceYear
});
export const setServiceYearListSpeakers = (serviceYearSpeakers) => ({
  type: 'SET_SERVICE_YEAR_SPEAKERS',
  serviceYearSpeakers
});
export const setSheetData = (sheetData) => ({
  type: 'SET_SHEET_DATA',
  sheetData
});
export const setSheetYears = (sheetYears) => ({
  type: 'SET_SHEET_YEARS',
  sheetYears
});
export const setCalendarIds = (calendarDataArray) => ({
  type: 'SET_CALENDAR_IDS',
  calendarDataArray
});
export const setSpDays = (spDays) => ({
  type: 'SET_SP_DAYS',
  spDays
});
export const speakerEdited = (truefalse) => ({
  type: 'SPEAKER_EDITED',
  truefalse
});
export const serviceEdited = (truefalse) => ({
  type: 'SERVICE_EDITED',
  truefalse
});
export const mustRender = (truefalse) => ({
  type: 'MUST_RENDER',
  truefalse
});
export const setAdmin = (admin) => ({
  type: 'SET_ADMIN',
  admin
});
export const setLoading = (loading) => ({
  type: 'SET_LOADING',
  loading
});
export const setHeaderLoading = (headerLoading) => ({
  type: 'SET_HEADER_LOADING',
  headerLoading
});
export const setRead = (read) => ({
  type: 'SET_READ',
  read
});
export const setWrite = (write) => ({
  type: 'SET_WRITE',
  write
});
export const setDrive = (drive) => ({
  type: 'SET_DRIVE',
  drive
});
export const setFreetime = (freetime) => ({
  type: 'SET_FREETIME',
  freetime
});
export const setChange = (change) => ({
  type: 'SET_CHANGE',
  change
});
export const setListCal = (listCal) => ({
  type: 'SET_LISTCAL',
  listCal
});
export const addDriveSheet = (spreadSheetId) => ({
  type: 'ADD_DRIVE_SHEET',
  spreadSheetId
});
export const emptyDriveSheet = (spreadSheetId) => ({
  type: 'EMPTY_DRIVE_SHEET',
  spreadSheetId
});

export const startAddSheet = (spread = {}) => {
  return (dispatch, getState ) => {
    const uid = getState().auth.uid;
    return database.ref(`users/${uid}/driveSheet`).push(spread)
      .then((ref)  => {
        const {
          spreadsId = ref.key,
          spreadsheetId = ' ',
          propertiesSpreadSheet = ' ',
          gsheetSheetId = ' '

        } = spread
        dispatch(addDriveSheet({
          spread
        }))
      })
  }
};
export const  startRemoveSheet = () => {
  return (dispatch,getState ) => {
    const uid = getState().auth.uid;
    return database.ref(`users/${uid}/driveSheet`).remove()
      .then(() => {
        console.log('Gsheets id deleted fron firebase')
      }).catch((e) => {
        console.log('Did not remove data from sheet,',e)
      })
  }
}

export const setTurnsToFile = (turnsToFile) => ({
  type: 'SET_TURNSTOFILE',
  turnsToFile
});

export const changeFromDataBase = (fromDataBase) => ({
  type: 'SET_FROM_DATABASE',
  fromDataBase
});


// export const startAddLastChageTimestamp= (lastChangeTimestamp,servYear) => {

//   //startRemoveTimestamp(servYear)
//   return ( dispatch, getState ) => {
//     const uid = getState().auth.uid;
//     return database.ref(`users/${uid}/serviceYear/${servYear}/lastChangeTimestamp`).set({lastChangeTimestamp: lastChangeTimestamp})
//     // .then(() => {
//     //   dispatch(editTimestamp(lastChangeTimestamp));
//     // })
//   }
// }

export const startAddUserName= () => {
  //startRemoveTimestamp(servYear)
  return ( dispatch, getState ) => {
    const uid = getState().auth.uid;
    const email = getState().auth.email;
    return database.ref(`users/${uid}/email`).set({email: email}).then(() => {
    })
  }
}

export const updateServiceData = (serviceProgramName, lastChangeTimestamp,  servYear) => {

  return (dispatch) => {
    dispatch(editFilterData( { serviceProgramName: serviceProgramName } ));
    //dispatch(startAddLastChageTimestamp( lastChangeTimestamp, servYear ));
    //dispatch(startAddUserName());
  }

}

import React, { useEffect, useState } from 'react';
import { Container, Paper, Tabs, Tab, Typography, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { useNavigate,Outlet, useLocation, Location  } from 'react-router-dom';
import { headerStyles, simpleContainerPaperStyle, subHeaderStyles, tabsStyle, tabStyle } from '../../styles/themeStylesSx';
import { RootState } from '../../store/ConfigureStore';
//import useLocales from '../hooks/useLocales';

export default function AdminContainer() {
  const auth: any = useSelector((state: RootState) => state.auth);
  const location: Location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('admin');
  //const { t } = useLocales();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === 'admin') {newValue = ''}
    navigate(`/admin/${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    const parts = location.pathname.split('/');

    const last = parts[parts.length - 1];
    if (last !== value) {
      setValue(last);
    };
   
  }, [location, value]);
  const windowWidth = window ? window.innerWidth : 1000;
  const [width, setWidth] = useState(windowWidth);

  useEffect(() => {
    setWidth(windowWidth);
  }, [windowWidth]);


  return (
    <Container>
      <Box sx = {{pt: 1}}>
        {auth.uid &&
          <Box>
            <Typography sx={ subHeaderStyles }>{'Asetukset'}</Typography>
            {/* <Typography sx={ subHeaderStyles }>{'Valitse toiminto valikosta'}</Typography> */}
          </Box>
        }
      </Box>
     
      <Tabs
        orientation={ width < 1000 ? 'vertical' : 'horizontal' }
        value={value === '' ? 'admin' : value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        sx={tabsStyle}
        aria-label = "admin tabs"
      >
         <Tab
          value="admin"
          label={'Hallinta'}
          sx={ tabStyle }
        />
        {/* <Tab
          disableRipple
          disableFocusRipple
          sx={ tabStyle }
          value="year"
          label={'Seuraohjelman vuosi'}
        /> */}
        <Tab
          value="checkProgram"
          label={'Tarkista seuraohjelma'}
          sx={ tabStyle }
        />
   
        {/* <Tab
          value="UpdateCals"
          label={'Päivitys'}
          sx={ tabStyle }
        />
        <Tab
          value="Users"
          label={'Käyttäjät'}
          sx={ tabStyle }
        /> */}
      </Tabs>
      <Paper sx={ simpleContainerPaperStyle }>
          <Outlet />
      </Paper>
    </Container>
  );
}
/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */

import React from 'react'
import { connect } from 'react-redux';
import {startEditTypesArray,startEditServDates,
  addSpeaker, startEditServSubjects} from '../../actions/speakers'
import {startEditServiceProgram} from '../../actions/services';
import updateSpeaker from './updateSpeaker';
import createSpeakersState from '../speakers/createSpeakersState';
import { submitSpeakersToCalendar } from './submitSpeakersToCalendar';
import { speakersToStateOnce } from '../../actions/speakers';
import updateSpeakerSubSheet from '../speakers/updateSpeakerSubSheet'
import { CircularProgress, FormControlLabel, Button, Checkbox,  TextField, Tooltip , Box} from '@mui/material';
import moment from 'moment';
import { editFilterData,  setSheetData,setCalendarIds} from '../../actions/filters';

class SpeakerListLoadFilters extends React.Component <any> {
  state: any = {
    calendarFocused:null,
    token : `bearer ${this.props.auth.idToken}`,
    speakers : this.props.speakers,
    filters:this.props.filters,
    spDays: this.props.filters.spDays,
    spDays60: this.props.filters.spDays60,
    spDays90: this.props.filters.spDays90,
    loadingDays: false,
    checked: false,
    serviceYear : this.props.filters.serviceYear,
    hryTilat:false,
    kahvio:false,
    yläkerranKokousHuone:false,
    keittiö:false,
    arkisto:false,
    autoTalli:false,
    kerhoHuone:false,
    loadingCals:false,
    speakersInitialized:false,
    speakersLoading:false,
    serviceRowsExcluded:[],
    clear: false
  }

  updateDays = (e: any) => {

    let spdays: any = e.target.value;
    let name : any = e.target.name;
    if (spdays === '' ) spdays = 15;
    let edit: any = ({[name]: spdays})
    this.props.editFilterData(edit);

    this.setState(() => ({ [name]: spdays }));
  }

 
  runSingleService: any = async (service1: any, speakers: any) => {
    if (service1.sspeaker === '') {
      let dateLimit: number  = this.state.spDays;
      const type = service1.type
      // if (type === '5') dateLimit = '5'
      const checkTypes = (x: any, typeService: any) => {
        if (typeService === x) return true
      }

      // eslint-disable-next-line array-callback-return
      const servspeakers = speakers.filter((speaker2: any) => {
        if (!speaker2.typesArray.some( (x: any) => checkTypes(x, type)))
          return speaker2
      })
      const checkSubjects: any = (x: any, subject: any) => {
        if (subject === x) return true
      }

      // eslint-disable-next-line array-callback-return
      const servspeakers1a = servspeakers.filter((speaker1a: any) => {
        if ((['5','6','0','9','8','10', '11']).indexOf(type) !== -1){
          if (!speaker1a.subjectArray.some( (x: any) => checkSubjects(x, service1.subject))) return speaker1a;
        }else 
        {
          return speaker1a
        }
      })

      const checkDates = (speakkeri: any, dateService: any) => {
        if (moment(dateService,'D.M.YYYY').isSameOrAfter(moment(speakkeri.apvm,'D.M.YYYY')) &&
          moment(dateService,'D.M.YYYY').isSameOrBefore(moment(speakkeri.ppvm,'D.M.YYYY')) ) {
          return true
        }
      };
      const servspeakers11 = servspeakers1a.filter((speaker2: any) => {
        if (!speaker2.datesArrayFinal.some((dates: any) => checkDates(dates,moment(service1.StartDate,'D.M.YYYY')))) {
          return speaker2
        }
      })

      const checkServDates = (speakkeri: any, dateService1: any, workDateLimit: any) => {
        if (moment(speakkeri,'D.M.YYYY').add(workDateLimit,'days').isSameOrAfter(moment(dateService1,'D.M.YYYY'))  ) {
          return true
        }
      }
      const checkDayLimit = (servspeakers11: any, StartDate: any, workDateLimit: any) =>{

        const servspeakers12 = servspeakers11.filter((speaker3: any) => {
          let dateLimitW = workDateLimit;
          if (speaker3.typesArray.some( (x: any) => x === '5' )) {
            dateLimitW = dateLimitW*3;
          }

          if (!speaker3.servDates.some((servdate: any) => checkServDates(servdate,moment(StartDate,'D.M.YYYY'),dateLimitW))) {
            return speaker3
          }
        })
        return servspeakers12
      };
     
      let workDateLimit = dateLimit;
      let servspeakers12 =  checkDayLimit(servspeakers11,service1.StartDate, workDateLimit);
      let workLength = servspeakers12.length;
      if (workLength === 0) {
        while (workLength === 0 && workDateLimit > 0) {
          workDateLimit = workDateLimit -1;
          servspeakers12 =  checkDayLimit(servspeakers11,service1.StartDate, workDateLimit)
          console.log('servspeakers12 in loop ', servspeakers12,service1)
          workLength = servspeakers12.length;
          if (workDateLimit === 0) {
            console.log('**********not found',servspeakers11)
          }
        }
      };

 
      
      const servspeakers13 = servspeakers12.map((current: any)=> {
        let duration: any = 0
        let durationEnd: any = -1;
        let freedays = [];
        let currentTypeDays: any[]= [];
        switch (type) {
          case '1':
            currentTypeDays = current.servDate1;
          break
          case '2':
            currentTypeDays = current.servDate2;
          break
          case '3':
            currentTypeDays = current.servDate3;
          break        
          case '4':
            currentTypeDays = current.servDate4;
          break 
          case '5':
            currentTypeDays = current.servDate5;
          break 
          case '6':
            currentTypeDays = current.servDate6;
          break 
          case '7':
            currentTypeDays = current.servDate7;
          break 
          case '8':
            currentTypeDays = current.servDate8;
          break 
          case '9':
            currentTypeDays = current.servDate9;
          break 
          case '10':
            currentTypeDays = current.servDate10;
          break 
          case '11':
            currentTypeDays = current.servDate11;
          break 
          case '0':
            currentTypeDays = current.servDate0;
          break 
        }
        freedays = current.datesArrayFinal.filter((day: any) => {
          return moment(day.ppvm,'D.M.YYYY').unix() < (moment(service1.StartDate,'D.M.YYYY').unix());        
        });
       
        durationEnd = -1;
        freedays.forEach((fr: any) => {
          duration  = duration + fr.duration;
        });
        if (freedays.length) {
          durationEnd = moment(freedays[freedays.length-1].ppvm).format('D.M.YYYY')
        }
      return { ...current, currentTypeDays, duration : duration, durationEnd: durationEnd }
      });
      
      const currentGroup = servspeakers13.map((current: any) => ({
        linenumber : current.linenumber,
        durationEnd: current.durationEnd,
        duration: current.duration,
        speaker: current.speaker,
        random: Math.random(),
        servDates : current.servDates,
        subjectArray : current.subjectArray,
        speakersTypesArray : current.speakersTypesArray,
        currentTypeDayArr: current.currentTypeDays,
        length: current.currentTypeDays.length,        
        lastDay : moment(current.servDates[current.servDates.length-1],'D.M.YYYY').unix() > moment(current.durationEnd,'D.M.YYYY').unix() ? current.servDates[current.servDates.length-1] : current.durationEnd,
      }))
      .sort((a: any,b: any ) =>{
        var x = a.random;
        var y = b.random;
        if (x < y) {return -1};
        if (x > y) {return 1}
        else return 0;
      })
      .sort((a: any,b: any ) =>{
        var x = a.length;
        var y = b.length;
        if (x < y) {return -1};
        if (x > y) {return 1}
        else return 0;
      });

      const filteredGroup = currentGroup.filter((fcurrent: any) => {
        return fcurrent.length === currentGroup[0].length  ;

      })
      .sort((a: any,b: any ) =>{
        var x = moment(a.lastDay,'D.M.YYYY').unix();
        var y = moment(b.lastDay,'D.M.YYYY').unix();
        if (x < y) {return -1};
        if (x > y) {return 1}
        else return 0;
      });

      if (filteredGroup.length > 0) {
        filteredGroup[0].currentTypeDayArr.push(service1.StartDate);
        filteredGroup[0].servDates.push(service1.StartDate);
        filteredGroup[0].subjectArray.push(service1.subject);
        filteredGroup[0].speakersTypesArray.push(service1.type);
        let workDays: any = ''
        switch (type) {
          case '1':
            workDays = {servDate1: filteredGroup[0].currentTypeDayArr, servDate1x: filteredGroup[0].currentTypeDayArr };
          break
          case '2':
            workDays = {servDate2: filteredGroup[0].currentTypeDayArr, servDate2x: filteredGroup[0].currentTypeDayArr  };
          break
          case '3':
            workDays = {servDate3: filteredGroup[0].currentTypeDayArr, servDate3x: filteredGroup[0].currentTypeDayArr };
          break        
          case '4':
            workDays = {servDate4: filteredGroup[0].currentTypeDayArr, servDate4x: filteredGroup[0].currentTypeDayArr };
          break 
          case '5':
            workDays = {servDate5: filteredGroup[0].currentTypeDayArr, servDate5x: filteredGroup[0].currentTypeDayArr };
          break 
          case '6':
            workDays = {servDate6 : filteredGroup[0].currentTypeDayArr, servDate6x: filteredGroup[0].currentTypeDayArr };
          break 
          case '7':
            workDays = {servDate7 : filteredGroup[0].currentTypeDayArr, servDate7x: filteredGroup[0].currentTypeDayArr };
          break 
          case '8':
            workDays = {servDate8 : filteredGroup[0].currentTypeDayArr, servDate8x: filteredGroup[0].currentTypeDayArr };
          break 
          case '9':
            workDays = {servDate9 : filteredGroup[0].currentTypeDayArr, servDate9x: filteredGroup[0].currentTypeDayArr };
          break 
          case '10':
            workDays = {servDate10: filteredGroup[0].currentTypeDayArr, servDate10x: filteredGroup[0].currentTypeDayArr } ;
          break 
          case '11':
            workDays = {servDate11: filteredGroup[0].currentTypeDayArr, servDate11x: filteredGroup[0].currentTypeDayArr }; 
          break 
          case '0':
            workDays = {servDate0: filteredGroup[0].currentTypeDayArr, servDate0x: filteredGroup[0].currentTypeDayArr } ;
          break 
        }
        const updates = ({
          id: filteredGroup[0].linenumber,
          speaker: filteredGroup[0].speaker,
          servDates : filteredGroup[0].servDates,
          subjectArray: filteredGroup[0].subjectArray,
          speakersTypesArray: filteredGroup[0].speakersTypesArray,
          durationEnd : filteredGroup[0].durationEnd,
          lastDay : filteredGroup[0].lastDay,
          duration: filteredGroup[0].duration,
          workDays
        })
        service1.sspeaker = filteredGroup[0].speaker;
        this.props.startEditServDates(updates.id, updates);
        const stateReturn1 =  speakers.map((ss: any) => {         
          if (updates.id === ss.linenumber ) {
            ss.servDates = [...updates.servDates];
            ss.subjectArray = [...updates.subjectArray];
            ss.speakersTypesArray = [...updates.speakersTypesArray];
            ss.durationEnd = updates.durationEnd;
            ss.lastDay = updates.lastDay;
            ss.duration = updates.duration;
            ss.workDays = updates.workDays;  
          };  
          return ss
        });
        //this.props.startEditServSubjects(stateReturn.id, updates.subjectArray);
        this.setState(() => ({speakers: stateReturn1}))
      }
      else {
        service1.sspeaker = 'nul';
      }

      await this.props.startEditServiceProgram(service1.id,
        service1,
        this.props.filters.serviceYear);

      await updateSpeaker(this.state.token,
        service1.linenumber,
        this.state.serviceYear,
        this.props.filters.currentSheet.sheetId,
        this.props.filters.currentSheet.range,
        this.props.filters.currentSheet.subSheet,
        service1.sspeaker,
        this.props.filters.currentSheet.columnSpeaker,
        this.props.filters.currentSheet.columnSpeaker,
        this.props.auth.email
        )
      }
    await new Promise( resolve => setTimeout(resolve, 3000));
    return service1
  }

  InitializeSpeakers1 = async (e: any) => {
    this.props.dispatchLoading(true)
    this.setState(() => ({speakersLoading:true}))
    const speakerArray = [...this.props.speakers]
    let serviceArray = [];
    if (this.state.clear) {
      serviceArray = this.state.serviceRowsExcluded.filter((x: any) => {if (['1','2','3','4','5','6','7','8','9','0','10','11'].indexOf(x.type) !== -1) return x})
    } else {
      serviceArray = this.props.services.filter ((x: any) => {if (['1','2','3','4','5','6','7','8','9','0','10','11'].indexOf(x.type) !== -1) return x})
    }
    await serviceArray.reduce( async (earlier: any, current: any) => {
      await earlier;
      return  await this.runSingleService(current ,speakerArray);

    }, Promise.resolve([]));
    this.setState(() => ({speakersLoading:false}));
    this.props.dispatchLoading(false);
  }

  handleCheckboxChange = (event: any) => {
    this.setState(() => ({ checked: !this.state.checked }))
  }
  clearLoading = async () =>{
    this.setState(() => ({ loadingDays: false }))
  }
  clearTurns = (e: any) => {
    this.setState(() => ({ clear: e.target.checked }))
    if (e.target.checked ) {
      if (window.confirm('Olet tyhjentämässä puhujavuorot ohjelmasta')) {
        let line : any = [];
        const emptyedSpeakers = this.props.speakers.filter((spe: any, i: any) => {

          return spe.mail !== '';
        });
        const xxx = emptyedSpeakers.map((row: any) => {
          line = []
          line[0] = row.speaker;
          line[1]= row.phone;
          line[2] = row.mail;
          line[3] = row.types;
          line[4] = row.dates;
          line[5] = row.random;
          line[6] = '';
          line[7] = row.linenumber;
          return line
        });
        
        this.emptySpeakerState(xxx, this.props.dispatch)       
        const serviceArrayExcluded = this.props.services.filter((row: any) => {
        return  row.excluded !== 'k' && row.excluded !== 'K'
        })
        .map((row: any) => ({...row, sspeaker: ''}))
        this.setState(() => ({serviceRowsExcluded: serviceArrayExcluded}))
      } else
        this.setState(() =>( {clear: false}))
    } else
     this.setState(() =>( {clear: false}));
  }

  emptySpeakerState = async (speakers: any, dispatch: any) => {
    const speakerState = await createSpeakersState(speakers);
    this.props.dispatchSpeakerState(speakerState)
  }

  UpdateSpeakerTurnsFromProgram = async (e: any) => {

    const retr = async () => {
      const year = 'speakers'+this.state.serviceYear;
      const sheetIdOfProgram = this.props.filters.sheetYears.filter((row: any) => {
        // eslint-disable-next-line eqeqeq
        if (row[0] == year) return row
      })
      const sheetData: any = ({
        serviceYear:year,
        spreadSheetId: sheetIdOfProgram[0][1],
        range:sheetIdOfProgram[0][2],
        columnAddInfo:sheetIdOfProgram[0][3],
        columnSpeaker:sheetIdOfProgram[0][4],
        subSheet: sheetIdOfProgram[0][5],
        speakerSubColumn: sheetIdOfProgram[0][6],
        speakerSubDayCol1: sheetIdOfProgram[0][7],
        speakerSubDayCol2: sheetIdOfProgram[0][8],
      })

      this.props.setSheetData(sheetData)
      const speakerArray = [...this.props.speakers]

      await speakerArray.reduce( async (earlier, current) => {
        await earlier;
        await updateSpeakerSubSheet(this.state.token,current.speaker,current.linenumber,current.servDates,this.state.serviceYear,current.servDate1,
          current.servDate2,current.servDate3,current.servDate4,current.servDate5,current.servDate6,current.servDate7,current.servDate8,
          current.servDate9,current.servDate0,current.servDate10,current.servDate11,
          sheetData.subSheet,sheetData.spreadSheetId, sheetData.speakerSubColumn, sheetData.speakerSubDayCol1, sheetData.speakerSubDayCol2,current.datesArrayFinal)


        await new Promise( resolve => setTimeout(resolve, 500));
        return [earlier];
      }, Promise.resolve([]));
      this.clearLoading();

      return sheetData
    }

    const updateCalendars = this.state.checked;
    this.setState(() => ({ loadingDays: true }))
    const speakerArray = [...this.props.speakers]
    const serviceArray = [...this.props.services]
    await submitSpeakersToCalendar (serviceArray, speakerArray, this.props.startEditServDates,
      this.props.addSpeaker,updateCalendars,this.state.token, this.props.filters);

    await retr();
  }

  handleCalChange = (e: any) => {
    const name = e.target.name;
    let value = e.target.checked;
    this.setState(() => ({ [name]: value }));
  }

  render () {
    return (
      <Box>
        <Box>
          <Box sx = {{justifyContent: 'space-around' , p: 2}}>
               
            { (this.state.loadingDays || this.state.speakersLoading) ? <CircularProgress />
                :
                <Tooltip title="Puhujalle tulevien vuorojen minimiväli" arrow>
              <TextField
                sx={{  width: '8%', m:1 }}
                label="Väli pv (15)"
                inputProps={{ type: 'number'}}
                autoFocus
                name='spDays'
                value={this.state.spDays}
                onChange={this.updateDays}
              />
              </Tooltip>
            }

            { (this.state.loadingDays || this.state.loadingCals || this.state.speakersLoading) ?
              <CircularProgress />
              :
              <Box >
                <Tooltip title="Vie seuraohjelman Puhuja-sarakkeeseen puhujat. Huomioidaan puhujan toiveet vapaa-ajoista ja tapahtumatyypeistä" arrow>
                <Button variant="contained" color="primary" sx={{justifyContent: 'space-around', mr:2, mb: 2, width:180 }}
                    onClick = {(e) => {e.preventDefault();this.InitializeSpeakers1( e )}}>Puhujavuorojen luonti    
                </Button>
                </Tooltip>
                <Tooltip title="Puhujatieto viedään kaikille riveille, jossa sarake B ei ole K" arrow>
                <FormControlLabel
                  control={
                  <Checkbox
                    checked= {this.state.clear}
                    onChange= {(e) => {this.clearTurns(e)}}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  }
                  label="Kaikki"
                />
                </Tooltip>
              </Box>
            }

            
            { (this.state.loadingDays || this.state.speakersLoading) ? <CircularProgress />
              :
              <Tooltip title="Ladataan puhujien kalentereihin vietävät vuorot seuraohjelmasta (Luetaan ohjelma läpi ja luodaan siitä puhujille kalenteritapahtumat" arrow>
              <Button variant="contained" color="primary" sx={{mr: 2, width:180 }}
                onClick = {(e) => {e.preventDefault();this.UpdateSpeakerTurnsFromProgram(e)}}>Vuorot ohjelmasta     </Button>
                </Tooltip>
            }

               
            { (this.state.loadingDays || this.state.speakersLoading) ? <CircularProgress />
              :
              <Tooltip title="Vuorot ladataan puhujien kalentereihin" arrow>
              <FormControlLabel
                control={
                  <Checkbox
                    checked= {this.state.checked}
                    onChange= {(e) => {this.handleCheckboxChange(e)}}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Kalenteriin"
              />
              </Tooltip>
            }
            <Box sx ={{ mt:2}}>
             <Button variant="contained" color={'primary'}>Paluu</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    filters: state.filters,
    services:state.services,
    speakers: state.speakers,
    auth: state.auth

  }
}
const mapDispatchToProps = (dispatch: any, props: any) => ({
  dispatchSpeakerState: (speakerState: any) => dispatch(speakersToStateOnce(speakerState)),
  dispatchLoading: (data: any )=> dispatch(editFilterData({loading: data})) ,
  setSheetData: (sheetData: any) => dispatch(setSheetData(sheetData)),
  editFilterData:(edit: any) => dispatch(editFilterData(edit)),
  startEditServDates: (linenumber: any, array: any) => dispatch(startEditServDates(linenumber,array)),
  startEditServSubjects: (linenumber: any, array: any) => dispatch(startEditServSubjects(linenumber,array)),
  startEditTypesArray: (linenumber: any, array: any, services: any) => dispatch(startEditTypesArray(linenumber,array)),
  addSpeaker: (data: any) => dispatch(addSpeaker(data)),
  startEditServiceProgram : (id: any, service: any, servYear: any) => dispatch(startEditServiceProgram(id,service,servYear)),
  setCalendarIds:(calendarIds: any) => dispatch(setCalendarIds(calendarIds))

});
export default connect(mapStateToProps,mapDispatchToProps)(SpeakerListLoadFilters);


/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import axios from 'axios';

const genericBaseUrl = `${process.env.REACT_APP_API_URL}/api/genericBaseUrl`;
const baseUrlByDates = `${process.env.REACT_APP_API_URL}/api/calpuhujabydatesfb`;
const baseUrlInsertGen = `${process.env.REACT_APP_API_URL}/api/inserteventgenfb`;
const baseUrlDelGen = `${process.env.REACT_APP_API_URL}/api/deleteeventgenfb`;
const aclListCalendarUrl = `${process.env.REACT_APP_API_URL}/api/aclListCalendar`;
const aclDeleteCalendarUrl = `${process.env.REACT_APP_API_URL}/api/aclDeleteCalendar`;
const aclUpdateCalendarUrl = `${process.env.REACT_APP_API_URL}/api/aclUpdateCalendar`;

const genericGetAll = async (token: any, newObject: any) => {
  const config = {
    headers: { Authorization: token },
  }
  //const request = await axios.get(genericBaseUrl,{params:{calendarId:calendarId, newObject}, headers: {Authorization : token} })
  try {
    const request = await axios.post(genericBaseUrl, newObject, config);
    return request.data;
  }catch(error: any) {
    console.log('errorxxxxx', error)
    console.log(error.response)
    return error.response.data.message
  }
}
const insertEventGen = async(newObject: any, token: any) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    await axios.post(baseUrlInsertGen, newObject,config);
  } catch(error: any) {
    console.log(error, error.response.status)
    throw error
  }
}
const getCalendarByDate = async (newObject: any, token: any) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const request = await axios.post(baseUrlByDates, newObject, config);
    return request.data;
  }catch(error: any) {
    console.log('erroro when reading calendar events', error, error.code)

  }
  
 
}

const aclListCalendar = async (calendarId: any, token: any) => {

  const request = axios.get(`${aclListCalendarUrl}/${calendarId}`,{headers: { Authorization: token }})

  return request.then(response => {
    return response.data
  } )
};

const aclDeleteCalendarUser = async (calendarId: any, userId: any , token: any) => {

  const request = axios.get(`${aclDeleteCalendarUrl}/${calendarId}/${userId}`,{headers: { Authorization: token }})

  return request.then(response => {
    return response.data
  } )
}

const aclUpdateCalendar = async (calendarId: any, userId: any ,scope: any, token: any ) => {

  const request = axios.post(`${aclUpdateCalendarUrl}/${calendarId}/${userId}`, scope, {headers: { Authorization: token }})

  return request.then(response => {
    return response.data
  } )
}
const removeEventGen = async (id: any, newObject: any, token: any) => {
  const request = axios.delete(`${baseUrlDelGen}/${id}`,{params:{calendarId:newObject.calendarId, sendUpdates: newObject.sendUpdates }, headers: { Authorization: token }})

  return request.then(response => {
    return response.data
  } )
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {

  getCalendarByDate: getCalendarByDate,
  genericGetAll: genericGetAll,
  removeEventGen :removeEventGen,
  insertEventGen:insertEventGen,
  aclListCalendar: aclListCalendar,
  aclDeleteCalendarUser: aclDeleteCalendarUser,
  aclUpdateCalendar:aclUpdateCalendar,
}


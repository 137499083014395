/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
//import React, { useState, useEffect ,useReducer, useContext } from 'react'
import calendarServices from '../../services/calendarServices'
import Moment from 'moment';
import 'moment-timezone'

const UpdateSpeakerCalendar = async (token: any, filters: any, speaker: any, startdate: any,
  starttime: any, enddate: any, endtime: any, subject: any, selite: any, prevspeaker: any, location: any, attendee: any, serv: any, deleteonly: any) => {

  const  removeEventFromCalendar = async (id: any, newObject: any, token: any) => {
    await calendarServices
      .removeEventGen(id, newObject, token)
      .catch(error => {
        console.log('puhujakalenteririvin delaus errori',error)})
  }

  const callCaleServ= async (newObject: any, token: any) => {
    await calendarServices    
      .getCalendarByDate(newObject, token)
      .then(initialLines => {
      if (Array.isArray(initialLines)) {
        initialLines.forEach((id: any) => {
          removeEventFromCalendar(id.id, newObject, token)
        })
      }
      else console.log('Not returned array', newObject)
      })
      .catch(error => {
        console.log('erreoddd',error)
      })
    if (deleteonly !== 'deleteonly') {
      await calendarServices
        .insertEventGen(newObject, token)
        .then(initialLines => {
        })
        .catch(error => {
          console.log('Puhujat kalenteritapahtuman luonti errori',error)
        })
    }
  };

  let workPrevSpeaker = '';
  let workSummary = '';
  let loca = ' ';
  if (!serv.Location) loca = ' '
  else loca =  serv.Location;
  let locupdarr = [];
  if (serv.type === '' || serv.type === ' ') {
    locupdarr = [loca];
  }
  else {
    locupdarr = ['Puhujat',loca]
  } 

  locupdarr.forEach(puh => {
    const startdatea =  Moment(startdate, 'D-M-YYYY').format('YYYY-MM-DD') + 'T' + Moment(starttime,'HH:mm' ).format('HH:mm');
    const enddatea =   Moment(enddate, 'D-M-YYYY').format('YYYY-MM-DD') + 'T' + Moment(endtime,'HH:mm' ).format('HH:mm');
    
    if (puh === 'Puhujat' ) {
      workSummary = subject + ' ' + speaker ;
      workPrevSpeaker = prevspeaker;
    }
    else if (puh !==' ') {
      if (puh === 'Keittiö' || serv.Location === 'Keittiö') {
        workSummary = serv.Toimikunta + ' / ' + subject + ' / ' + serv.Selite;
      } else {
        workSummary = subject;
      };
      attendee = 'matti.friman@helsinginrauhanyhdistys.fi';
      workPrevSpeaker = serv.subject;
    }
    if (attendee === ' ') attendee = 'matti.friman@helsinginrauhanyhdistys.fi';
    if (attendee === '') attendee = 'matti.friman@helsinginrauhanyhdistys.fi';

    const calendarIdRow = filters.calendarDataArray.filter((cale: any) => {
      return cale.calendar === puh;
    })

    let calendarIdW = '';
    if (calendarIdRow.length === 0) {
      calendarIdW = ''
    }
    else {
      calendarIdW = calendarIdRow[0].calendarId
    }
    if (puh !== '' && puh !== ' ') {

      let sendUpdates = 'all'
      if (process.env.REACT_APP_MAIL==='testi') {
        sendUpdates = 'none';
      }
      if (process.env.REACT_APP_MAIL==='testi') {
        sendUpdates = 'none'
      }
      const newObject = ({
        calendarId:calendarIdW,
        speaker: speaker,
        attendee: attendee,
        sendUpdates: sendUpdates,
        start: {
          'dateTime' : Moment.tz(startdatea, 'Europe/Helsinki').format(),
          'timeZone': 'Europe/Helsinki'
        },
        end: {
          'dateTime' : Moment.tz(enddatea, 'Europe/Helsinki').format(),
          'timeZone': 'Europe/Helsinki'
        },
        'summary': workSummary ,
        'location': location,
        'creator': 'seurat@helsinginrauhanyhdistys.fi',
        selite: selite,
        q: workPrevSpeaker
      })
      callCaleServ(newObject, token)
    };
  })
}

export default UpdateSpeakerCalendar;
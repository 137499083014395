/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector, useDispatch } from 'react-redux';
import selectServices from '../../selectors/services';
import UpdateServiceLine from './UpdateServiceLine';
import { editFilterData } from '../../actions/filters';
import { RootState } from '../../store/ConfigureStore';
import { Box, IconButton, Typography,  Table, TableBody, TableCell, TableHead, TableRow, Tooltip, CircularProgress } from '@mui/material';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import SidePanel from '../sidepanel/SidePanel';
import { colors } from '../../styles/themeStylesSx';
import EditIcon from '@mui/icons-material/Edit';
import { VisibilityRounded, StrikethroughSRounded } from '@mui/icons-material';
import UpdateServicePage from './UpdateServicePage';
import UpdateServiceStrike from './UpdateServiceStrike';

type ServiceStateType = {
  openStrike?: boolean;
  openSpeaker?: boolean;
  editLine?: boolean;
  serviceId?: number;
  add?: boolean;
  linenumber?: number;
  id?: any;
};
let typeArray: any = [] ;

const ServiceProgramList = () => {
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  //const auth: any = useSelector((state: RootState) => state.auth);
  const [state, setState] = useState<ServiceStateType>({ openStrike: false, openSpeaker: false, editLine: false });
  //const token = `bearer ${auth.idToken}`;
  const services: any = useSelector((state: RootState) => state.services);
 
  useEffect (() => {
    if (services.length > 0 ) {
      const servicesWork = [...services];
      if (servicesWork.filter((work: any) => work.sspeaker.toLowerCase().includes(filters.text.toLowerCase())).length === 0){
        dispatch(editFilterData({text : ''}));
      }
    };
  },[])

  if (filters.loading ) return   <CircularProgress />
  if (filters.listAll)
    typeArray = ['1','2','3','4','5','6','7','8','9','0','10','11', '', ' ']
  else typeArray = ['1','2','3','4','5','6','7','8','9','0','10','11'];
  const workService: any = selectServices(services, filters);  
  return(    
    <Box>
      <SidePanel
      open={ state.openSpeaker === true }
      close={ () => setState({ openSpeaker: false })}
      >
        <Box> 
          {state.openSpeaker &&                    
            <UpdateServicePage serviceId= {state.id } close ={ () => setState({ openSpeaker: false, openStrike: false })} />
          }        
        </Box>
      </SidePanel>
      <SidePanel
      open={ state.openStrike === true }
      close={ () => setState({ openStrike: false })}
      >
        <Box>
          {state.openStrike &&
            <UpdateServiceStrike serviceId= {state.id } close ={ () => setState({ openStrike: false, openSpeaker: false})} />
          }
        </Box>
      </SidePanel>
      <SidePanel
        open={ state.editLine === true }
        close={ () => setState({ editLine: false })}
      >
        <Box>
          {state.editLine &&
            <UpdateServiceLine serviceId= {state.id } close ={ () => setState({ openStrike: false, openSpeaker: false, editLine: false })} />     
          }
        </Box>
      </SidePanel>
      <Box>
        <Typography   sx={ subHeaderStyles }>{`Puhujavuorot ${filters.serviceProgramName}`}</Typography>
        <Table sx={{ display: { xs: 'none', md: 'inline-table' }, width: '90%' }}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>          
              <TableCell sx={{width:10}}>{'Puhuja'}</TableCell>
              <Tooltip title = "Muuta puhuja, yliviivaa, muuta riviä" arrow>
              <TableCell sx= {{width: "1px" }}></TableCell>
              </Tooltip>
              <TableCell sx={{width: 10 }}>{'Alk'}</TableCell>
              <TableCell sx={{ width: 10 }} >{'Tilaisuus'}</TableCell>
              <TableCell sx={({ width: 10 }) } >{'Selite'}</TableCell>
              <TableCell sx={{ width: 10 }}>{'Isäntä'}</TableCell>
              <TableCell>{'Äänitarkk'}</TableCell>
              <TableCell>{'Säestäjä'}</TableCell>
              <TableCell>{'Esilaulaja'}</TableCell>
              <TableCell>{'Valvonta'}</TableCell>
              <TableCell>{'Tulkki'}</TableCell>
              <TableCell>{'Kassa'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workService.filter((filtered: any) => (typeArray.indexOf(filtered.type) !== -1))
              .map((service: any, i: any) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return (
                  <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${service.id}_row16`}>
                    {service.strike !== 'y' &&
                      <TableCell sx={{ backgroundColor, width: 10 }}>{ service.sspeaker } </TableCell>
                    }
                    {service.strike === 'y' &&
                      <TableCell sx={{ backgroundColor, textDecoration:"line-through", width: 10 }}>{ service.sspeaker } </TableCell>
                    }
                    <TableCell 
                      sx= {{ backgroundColor, borderTopRightRadius: 15, borderBottomRightRadius: 15, m:2, width: "1px"}}>
                      <IconButton  onClick={() => setState({ openSpeaker: true, openStrike: false, editLine: false, id: service.id })}><VisibilityRounded /></IconButton>
           
                      <IconButton onClick={() => setState({ openSpeaker: false, openStrike: true, editLine: false, id: service.id })}><StrikethroughSRounded/></IconButton>
          
                      <IconButton onClick={() => setState({ openSpeaker: false, openStrike: false, editLine: true,  id: service.id })}><EditIcon/></IconButton>
                    </TableCell>
                    {service.strike !== 'y' &&
                      <TableCell sx={{backgroundColor, width: 10 }}>{ service.Vkonpäivä + ' ' + service.StartDate + ' ' + service.StartTime}</TableCell>
                    }
                    {service.strike === 'y' &&
                      <TableCell sx={{ backgroundColor, width: 10, textDecoration:"line-through"}}>{ service.Vkonpäivä + ' ' + service.StartDate + ' ' + service.StartTime}</TableCell>
                    }
                    {service.strike !== 'y' &&
                      <TableCell sx={{backgroundColor, width: 10 }}>{ service.subject }</TableCell>
                    }
                    {service.strike === 'y' &&
                      <TableCell sx={{ backgroundColor, textDecoration:"line-through", width: 10 }}>{ service.subject }</TableCell>
                    }
                    {service.strike === 'y' &&
                      <TableCell sx={{backgroundColor, width: 10 }}>{ service.Selite}</TableCell>
                    }
                    {service.strike !== 'y' &&
                      <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, width: 10 }}>{ service.Selite}</TableCell>
                    }
                    <TableCell sx={{backgroundColor, width: 10}}>{ service.Seuraisäntä }</TableCell>
                    <TableCell sx={{backgroundColor}}>{ service.Äänentoisto }</TableCell>
                    <TableCell sx={{backgroundColor}}>{ service.Säestäjä }</TableCell>
                    <TableCell sx={{backgroundColor}}>{ service.Esilaulaja }</TableCell>
                    <TableCell sx={{backgroundColor}}>{ service.Valvontaryhmänvetäjä }</TableCell>
                    <TableCell sx={{backgroundColor}}>{ service.Englannintulkkaus }</TableCell>
                    <TableCell sx={{backgroundColor}}>{ service.Kassaryhmä }</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </Box>
      
      <Table sx={{ display: { xs: 'inline-table', md: 'none' }, width: '75%' }} >
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>          
            <TableCell sx ={{width:10, fontWeight:"bold", borderBottom:1}}>{'Puhuja'}</TableCell>
            <TableCell sx={{width:"1px"}}></TableCell>
            <TableCell sx={{width:10,  pl:0, fontWeight:"bold",  borderBottom:1}}>{'Aika'}</TableCell>
          </TableRow>
        </TableHead>
       
        {
        workService.filter((filtered: any) => (typeArray.indexOf(filtered.type) !== -1))
          .map((service: any, i: any) => {
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
            return (
              <TableBody key={`${service.id}_row1`}>
                <TableRow  key={`${service.id}_row2`}>
                  {service.strike !== 'y' &&
                    <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, width: 10 }}>{ service.sspeaker } </TableCell>
                  }
                  {service.strike === 'y' &&
                    <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, width: 10, textDecoration:"line-through" }}>{ service.sspeaker } </TableCell>
                  }
                  <TableCell sx={{
                    backgroundColor,
                    borderTopRightRadius: 15, borderBottomRightRadius: 15, width: "1px"}}>
                    <IconButton sx={{p: 0, m: 1}} onClick={() => setState({ openSpeaker: true, openStrike: false, id: service.id })}><VisibilityRounded /></IconButton>
                    <IconButton sx={{p: 0, m:1 }} onClick={() => setState({ openSpeaker: false, openStrike: true, id: service.id })}><StrikethroughSRounded/></IconButton>
                    <IconButton sx={{p: 0, m:1 }} onClick={() => setState({ openSpeaker: false, openStrike: false, editLine: true,  id: service.id })}><EditIcon/></IconButton>
                  </TableCell>
                  {service.strike !== 'y' &&
                    <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, borderBottomRightRadius: 15, width: 10, pl:0 }}>{ service.Vkonpäivä + ' ' +
                    service.StartDate + ' klo ' + service.StartTime + ' ' + service.subject + ' ' + service.Selite }</TableCell>
                  }
                  {service.strike === 'y' &&
                    <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, borderBottomRightRadius: 15, textDecoration:"line-through", width: 10, pl:0 }}>{ service.Vkonpäivä + ' ' +
                    service.StartDate + ' klo '  + service.StartTime +  ' ' + service.subject + ' ' + service.Selite }</TableCell>
                  }
                </TableRow>
                {  ['1','2','3','4','8','0','10','11'].indexOf(service.type) !== -1 &&
                <TableRow sx={{height: 20,  color: 'secondary.main'}} key={`${service.id}_row5`}>
                  <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15,  m:10, width: 10 }}>
                    { 'Seuraisäntä: ' + service.Seuraisäntä +
                  ', Äänentoisto: ' + service.Äänentoisto + ', Säestäjä: ' +service.Säestäjä }
                  </TableCell>
                  <TableCell sx={{ backgroundColor, borderTopRightRadius: 15, borderBottomRightRadius: 15, width: 10, pl:0}}></TableCell>
                  <TableCell  sx={{backgroundColor, height: 20,  color: 'secondary.main', width: 10, borderTopRightRadius: 15, borderBottomRightRadius: 15,  borderTopLeftRadius: 15, pl:0}}>
                  { 'Esilaulaja: ' + service.Esilaulaja + ', Valvonta: ' + service.Valvontaryhmänvetäjä + 
                    ', Translation ' + service.Englannintulkkaus + ', Kassaryhmä: ' + service.Kassaryhmä  }</TableCell>
                </TableRow>
                }
              </TableBody>
            )
          })
        }
           
      </Table>
      {/* } */}
    </Box>
  )
}

export default ServiceProgramList;
import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import Moment from "moment";
import { Box, Button, TextField } from '@mui/material';
import { editFilterData } from '../../actions/filters';
import { RootState } from '../../store/ConfigureStore';

import calendarServices from "../../services/calendarServices";
type genCalType = {
  close: () => void,
  service:any,
  calendarIds: any,
  token: string,
  deleteOnly: string;
}
let ok: string = '';
ok = 'no'


const GenCalUpd = (props: genCalType) => {
  
  const filters: any = useSelector((state: RootState) => state.filters);
  const close = props.close;
  const dispatch = useDispatch();
  const startdatea =  Moment(props.service.StartDate, 'D-M-YYYY').format('YYYY-MM-DD') + 'T' + Moment(props.service.StartTime,'HH:mm' ).format('HH:mm');
  const enddatea =   Moment(props.service.EndDate, 'D-M-YYYY').format('YYYY-MM-DD') + 'T' + Moment(props.service.EndTime,'HH:mm' ).format('HH:mm');
  let errorInLocation= false;
  let worksummary = '';
  worksummary =  props.service.Location === 'Keittiö' ? props.service.Toimikunta + ' / ' + props.service.subject + ' / ' + props.service.Selite : props.service.subject;
  let sendUpdates = 'all'
  if (process.env.REACT_APP_MAIL==='testi') {
    sendUpdates = 'none';
  }

  const caleLocation = props.service.Location;
 
  const newObject = ({
    calendarId: props.calendarIds,
    attendee: 'matti.friman@helsinginrauhanyhdistys.fi',
    start: {
      'dateTime' : Moment.tz(startdatea, 'Europe/Helsinki').format(),
      'timeZone': 'Europe/Helsinki'
    },
    end: {
      'dateTime' : Moment.tz(enddatea, 'Europe/Helsinki').format(),
      'timeZone': 'Europe/Helsinki'
    },
    'summary': worksummary,  
    'location': caleLocation,
    'creator': 'seurat@helsinginrauhanyhdistys.fi',
    selite: props.service.selite,
    sendUpdates: sendUpdates,
    q: props.service.subject
  })
  
  if (!caleLocation) {
    errorInLocation =true;
  }else {
   errorInLocation = false;
  }
  const calCalls =  async (token: any, newObject: any  ) => {
    await calendarServices
      .genericGetAll( props.token, newObject )
      .then(initialLines => {
        initialLines.forEach((id: any) => {
          calendarServices
            .removeEventGen(id.id, newObject, props.token)
            .catch(error => {
              console.log('pxxxxx kalenteririvin delaus errori',error)
              
            dispatch(editFilterData({error: true, errorMessage: 'Kalenterikäsittelyssä-delete vikaa', loading: false }))
            })
        })
      })
      .catch(error => {console.log('erreodddddd',error);
        dispatch(editFilterData({error: true, errorMessage: 'Kalenterikäsittelyssä-luku vikaa', loading: false }))
      });
    if (props.deleteOnly !== 'deleteOnly') {  
      await calendarServices
        .insertEventGen(newObject, props.token)
        .catch(error => {
          console.log('Kalenteritapahtuman luonti errori GenCalUpd',error)
          dispatch(editFilterData({error: true, errorMessage: 'Kalenterikäsittelyssä-Insert vikaa', loading: false }))
      })
    }
 
  }
 const cal = async () => {
   await calCalls(props.token, newObject);
   console.log('onko filtterissä vielä errori', filters.error)
   dispatch(editFilterData({lineId: props.service.linenumber}));
   errorInLocation = false
   close()
   }
   const clo = async () => {  
    errorInLocation = false;
    close()
    }

   
  //
  return (
    <Box>
       <TextField
        sx={{ pt:1 }}
        label={'Lisätään tapahtuma kalenteriin'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ props.calendarIds }
        
      />
       <TextField
        sx={{ pt:1 }}
        label={'Hry:n tila'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ errorInLocation ? 'Korjaa Location seuraohjelman riville': props.service.Location }        
      />
       <TextField
        sx={{ pt:1 }}
        label={'Apvm'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ props.service.StartDate }        
      />
       <TextField
        sx={{ pt:1 }}
        label={'Klo'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ props.service.StartTime }        
      />
       <TextField
        sx={{ pt:1 }}
        label={'Tilaisuus'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ props.service.subject }
        
      />
      {errorInLocation === false &&
        <Button color="primary" variant="contained" sx={{ ml: 1 }}
          onClick= {cal}> {('Submit')}
        </Button>   
      }
      
      <Button color="primary" variant="contained" sx={{ ml: 1 }}
        onClick= {clo}>
              {('Paluu')}
            </Button> 
    </Box>
  )
}

export default GenCalUpd;
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
// import programService from '../../services/programService'
// import moment from 'moment';
// const submitLeaveTimes = async (token:  any, speaker: any, filters: any ) => {
//   const leaveTimes= filters.daysToState.filter((da: any) =>
//     !da.deleted).map((da: any) => `${moment(da.startDate).format('D.M.YYYY')} -  ${moment(da.endDate).format('D.M.YYYY')}` )
//   const stringData = leaveTimes.toString();
//   await programService
//     .updatespleaves(token,speaker.linenumber,stringData,filters.serviceYearSpeakers, filters.sheetId, filters.columnAddInfo, filters.subSheet)
//     .catch(error => {
//       console.log('error updating speakers leavetimes',error)
//     })
//   return 'kukkuutikukkuu'
// }

// export default submitLeaveTimes;
import programService from '../../services/programService';
import moment from 'moment';

interface Filters {
  daysToState: Array<{ 
    deleted?: boolean; 
    startDate: Date; 
    endDate: Date; 
  }>;
  serviceYearSpeakers: string;
  sheetId: string;
  columnAddInfo: string;
  subSheet: string;
}

interface Speaker {
  linenumber: string;
}

const formatLeaveTimes = (days: Array<{ deleted?: boolean; startDate: Date; endDate: Date }>): string => {
  return days
    .filter(day => !day.deleted)
    .map(day => `${moment(day.startDate).format('D.M.YYYY')} - ${moment(day.endDate).format('D.M.YYYY')}`)
    .toString();
};

const submitLeaveTimes = async (
  token: any,
  speaker: Speaker,
  filters: Filters
): Promise<string> => {
  try {
    const leaveTimesString = formatLeaveTimes(filters.daysToState);

    await programService.updatespleaves(
      token,
      speaker.linenumber,
      leaveTimesString,
      filters.serviceYearSpeakers,
      filters.sheetId,
      filters.columnAddInfo,
      filters.subSheet
    );

    return 'kukkuutikukkuu';
  } catch (error) {
    console.error('Error updating speaker leave times:', error);
    throw error; // Throw the error if you want the caller to handle it
  }
};

export default submitLeaveTimes;

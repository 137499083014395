// /* eslint-disable semi */

import submitGetCalendarIdArr from './submitGetCalendarIdArr'
import database from '../../firebase/firebase';
import submitGenCalRead from './submitGenCalRead';

// const calendarList = ['services', 'Hry tilat', 'Autotalli', 'Juhlapyhät', 'Kahvio', 'Keittiö', 'Yläkerran kokoushuone', 'Kerhohuone', 'Arkisto', 'Leivintupa', 'Sali', 'Sauna', 'Toimisto']

// const submitReadHryCals = async ( uid: any, dispatch: any, setCalendarIds: any, token: any, filters: any, auth: any) => {
//   const servYear=filters.serviceYear;
//   const returnable: any = ({
//     'services':[],
//     'Hrytilat':[],
//     'Autotalli': [],
//     'Juhlapyhät':[],
//     'Kahvio':[],
//     'Keittiö':[],
//     'Yläkerrankokoushuone': [],
//     'Kerhohuone' :[],
//     'Arkisto': [],
//     'Leivintupa':[],
//     'Sali':[],
//     'Sauna':[],
//     'Toimisto':[]

//   });

//   const calCall = async (id: any, calendar: any) => {
//     const translatedCalendars = ['services', 'Hrytilat', 'Autotalli', 'Juhlapyhät', 'Kahvio', 'Keittiö', 'Yläkerrankokoushuone', 'Kerhohuone', 'Arkisto', 'Leivintupa', 'Sali', 'Sauna', 'Toimisto'];
//     const searchCalendar = translatedCalendars[calendarList.indexOf(calendar)];
//     const calendarRows = await submitGenCalRead(id, token, filters.startDate,filters.endDate, dispatch);
//     returnable[searchCalendar] = calendarRows;
//     return
//   }
//   const retr1 = async (calendarDataArray: any) => {
//     for ( const row of calendarDataArray)  {
//       await calCall(row.calendarId, row.calendar);
//     }
//   }
//   const retr = async () => {
//     const calendarDataArray1 = await submitGetCalendarIdArr(token);
//     dispatch( setCalendarIds(calendarDataArray1))
//     return calendarDataArray1
//   }
//   const checkVersion = async () => {
//     if (filters.sheetYears.length > 0)
//       await checkChangesOfService(token,filters,auth)
//   }
//   const getSheet = async () => {
//     //await checkVersion();
//     const returnable = await retr ();
//     await retr1(returnable);
//     return;
//   }


//   await getSheet()

//   let serviceList: any = []
//   await database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram`)
//     .once('value')
//     .then((snapshot: any) => {
//       snapshot.forEach((childSnapshot: any) => {
//         serviceList.push({
//           serviceId:childSnapshot.key,
//           ...childSnapshot.val()
//         })
//       })
//       returnable.services = serviceList.filter((serv: any) => {
//         return serv.strike.toLowerCase() === 'n';
//       });
//     })
//   return returnable
// }
// export default submitReadHryCals
const calendarList = [
  'services', 'Hry tilat', 'Autotalli', 'Juhlapyhät', 'Kahvio', 'Keittiö', 
  'Yläkerran kokoushuone', 'Kerhohuone', 'Arkisto', 'Leivintupa', 'Sali', 'Sauna', 'Toimisto'
];

const translatedCalendars = [
  'services', 'Hrytilat', 'Autotalli', 'Juhlapyhät', 'Kahvio', 'Keittiö', 
  'Yläkerrankokoushuone', 'Kerhohuone', 'Arkisto', 'Leivintupa', 'Sali', 'Sauna', 'Toimisto'
];

type ReturnableType = {
  [key: string]: any[];
};

const submitReadHryCals = async (
  uid: string, 
  dispatch: (action: any) => void, 
  setCalendarIds: (ids: any) => void, 
  token: string, 
  filters: { serviceYear: string; startDate: string; endDate: string; sheetYears: string[] }, 
  auth: any
): Promise<ReturnableType> => {
  const serviceYear = filters.serviceYear;
  const returnable: ReturnableType = Object.fromEntries(
    translatedCalendars.map((calendar) => [calendar, []])
  );

  const fetchCalendarData = async (id: string, calendar: string): Promise<void> => {
    const translatedCalendar = translatedCalendars[calendarList.indexOf(calendar)];
    const calendarRows = await submitGenCalRead(id, token, filters.startDate, filters.endDate, dispatch);
    returnable[translatedCalendar] = calendarRows;
  };

  const fetchAllCalendarData = async (calendarDataArray: any[]): Promise<void> => {
    for (const row of calendarDataArray) {
      await fetchCalendarData(row.calendarId, row.calendar);
    }
  };

  const retrieveCalendarData = async (): Promise<any[]> => {
    const calendarDataArray = await submitGetCalendarIdArr(token);
    dispatch(setCalendarIds(calendarDataArray));
    return calendarDataArray;
  };

  const processCalendars = async (): Promise<void> => {
    const calendarData = await retrieveCalendarData();
    await fetchAllCalendarData(calendarData);
  };

  await processCalendars();

  const serviceList: any[] = [];
  await database
    .ref(`users/${uid}/serviceYear/${serviceYear}/serviceProgram`)
    .once('value')
    .then((snapshot: any) => {
      snapshot.forEach((childSnapshot: any) => {
        serviceList.push({
          serviceId: childSnapshot.key,
          ...childSnapshot.val()
        });
      });

      returnable.services = serviceList.filter(
        (service: any) => service.strike.toLowerCase() === 'n'
      );
    });

  return returnable;
};

export default submitReadHryCals;

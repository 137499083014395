/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import programService from '../../../services/programService';
import submitGetSheetId from '../submitGetSheetId';
import submitSheetRetr from '../submitSheetRetr';
import submitReadHryCals from '../../calendars/submitReadHryCals';
import { setCalendarIds } from '../../../actions/filters';
import moment from 'moment';
import { editFilterData } from '../../../actions/filters';
import { RootState } from '../../../store/ConfigureStore';
import { Box, FormControl, MenuItem, Select, IconButton,  InputLabel, Typography,  Table, TableBody, TableCell, TableHead, TableRow, Tooltip, CircularProgress } from '@mui/material';
import { subHeaderStyles } from '../../../styles/themeStylesSx';
import SidePanel from '../../sidepanel/SidePanel';
import { colors } from '../../../styles/themeStylesSx';
import { GenGetAllSubSheets } from '../genComponents';
import EditIcon from '@mui/icons-material/Edit';
import genServiceLinesSelector from '../../../selectors/genServiceLinesSelector';
import CalendarListFilters from '../../calendars/CalendarListFilters';
import { useNavigate } from 'react-router-dom';
import GenCalUpd from '../../calendars/GenCalUpd';
import MouseOverPopOver from '../../../utilities/MouseOverPopover';
let workServiceArray: any = [];
let serviceArray: any = [];

type ServiceStateType = {
  openStrike?: boolean;
  openSpeaker?: boolean;
  editLine?: boolean;
  serviceId?: number;
  add?: boolean;
  linenumber?: number;
  id?: any;
  openGroup?: boolean;
  line?: any;
  newLineToimikunta?: any;
  caleId?: any;
  calendarId? : any;
  service?: any;
  calendarsState?: any;
};
const ServiceAnalyze = () => {
  let popOverText = 'Lisää seuraohjelman riviä vastaava tilakalenteritapahtuma';
  // MouseOverPopover() 
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  const auth: any = useSelector((state: RootState) => state.auth);
  const [state, setState] = useState<ServiceStateType>({ openGroup: false, openSpeaker: false, editLine: false, line:'' , newLineToimikunta : 'Toimikunta', caleId: '', service: '', calendarsState: []});
  const token = `bearer ${auth.idToken}`;
  const serviceState: any = useSelector((state: RootState) => state.services);
  const [sheetArray, setSheetArray] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedLines, setSelectedLines] = useState<any>([]);
  const [calendarArrayState, setCalendarArrayState] = useState([]);
  const caleList: any = ['Arkisto', 'Autotalli', 'Kahvio', 'Keittiö', 'Kerhohuone', 'Leivintupa', 'Yläkerran kokoushuone', 'Hry tilat'];
  let navigate = useNavigate();
  let componentMounted = true;
  let calendarArray: any = [];    
  useEffect (() => {
    if (serviceState.length === 0 && !filters.loading) {
      navigate("/seuraohjelma")
    }
    else {
      if (!filters.loading) {

        const calendarRead = async (uid: any, dispatch: any, setCalendarIds: any, token: any, filters: any,auth: any, serviceState: any ) => {
          const results: any = await submitReadHryCals (uid, dispatch, setCalendarIds, token, filters, auth);
          await serviceState.reduce (async (earlier: any, rivi: any) => {      
            if (rivi.Location !== '' && rivi.Location !== ' ' && typeof(rivi.Location) !== undefined  && rivi.strike !=='y') {
              const workStart = moment(rivi.StartDate,'D.M.YYYY').format('YYYY-MM-DDT')  + moment(rivi.StartTime,'HH:mm').format('HH:mm') + ':00';            
              const workStartFin =  moment.tz(workStart, 'Europe/Helsinki').format();         
              const workEnd = moment(rivi.EndDate,'D.M.YYYY').format('YYYY-MM-DDT') + moment(rivi.EndTime,'HH:mm').format('HH:mm') + ':00';
              const workEndFin =  moment.tz(workEnd, 'Europe/Helsinki').format();        
              const translatedLocation = ['Arkisto', 'Autotalli', 'Kahvio', 'Keittiö', 'Kerhohuone', 'Leivintupa', 'Yläkerrankokoushuone', 'Hrytilat' ]
              const searchValues = translatedLocation[caleList.indexOf(rivi.Location)];
              if (typeof(searchValues) !== 'undefined') {
                const caleLine = results[searchValues].filter((line: any) => {
                  return moment(line.start.dateTime,'YYYY-MM-DDTHH:mm:ss').unix() === moment( workStartFin,'YYYY-MM-DDTHH:mm:ss').unix() &&
                    moment(line.end.dateTime,'YYYY-MM-DDTHH:mm:ss').unix() === moment( workEndFin,'YYYY-MM-DDTHH:mm:ss').unix();
                });
                calendarArray[rivi.linenumber] = false;
                if (caleLine.length > 0) {
                  calendarArray[rivi.linenumber] = true;
                }
                else{
                  calendarArray[rivi.linenumber] = false;
                }
              }     
            }  
            else {
              calendarArray[rivi.linenumber] = false;
            };    
          })
          setCalendarArrayState(calendarArray);
          return calendarArray;        
        }
        const getServiceProgram = async () => {         
          if (!Array.isArray(serviceState)) {
            dispatch(editFilterData({error: true, errorMessage: 'error when getting  serviceprogram from redux'}));
          } 
          else {          
            await calendarRead (auth.uid,dispatch,setCalendarIds,token,filters,auth, serviceState);  
            serviceArray = await genServiceLinesSelector(serviceState, filters.startDate, filters.endDate);
            setSelectedRows(serviceArray);   
            setSelectedLines(serviceArray) ;
            //const callTable = ['types','TMK-nimet', 'location', 'seuraIsannat', 'saestajat', 'esilaulajat', 'valvojat','aanentoisto','tulkit'];        
            const callTable = [ 'types','TMK-nimet']; 
            const sheetArray = await GenGetAllSubSheets(dispatch, filters, token, filters.serviceYear, callTable);
            setSheetArray(sheetArray);  
          }
        }
        const getSheet = async () => {
          await getServiceProgram();
         
          dispatch(editFilterData({loading: false}));
        }

        //toggleLoading(true)
        dispatch(editFilterData({ loading: true, error: false, errorMessage:'', lineId:0 }));
        getSheet()
        if (componentMounted) {
        }
        return () => { // This code runs when component is unmounted
          // eslint-disable-next-line react-hooks/exhaustive-deps
          componentMounted = false; // (4) set it to false if we leave the page
        }
      }
    }
  }, [ filters.serviceYear ]);
 

  useEffect (() => {
  if (filters.lineId !== 0 ) {
   const workArray: any = [...calendarArrayState]
   workArray[filters.lineId] = true;
   setCalendarArrayState(workArray);

  }
  },[filters.lineId]);

  if (filters.loading ) return  <div> filters is loading....odota, että seuraohjelma latautuu kokonaan <CircularProgress /></div> 
  const handleChange =  (name : any, value: any) => {
    if (value === 'Toimikunta'){ 
      setSelectedLines(selectedRows);
      setState({[name] : value})
    }
  
    else {  
      setState({[name] : value})
      setSelectedLines(selectedRows.filter((service: any) => service.Toimikunta === value));
    }
  }
  const lineClicked = async(til: any) => {
    setState({ ...state, editLine: true,  id: til.serviceId, caleId: filters.calendarDataArray.find((cal: any) => {
      return cal.calendar === til.Location}), service : til}  )
  }
  return(
    <Box>
      <Box>
        <SidePanel
        open={ state.editLine === true }
        close={ () => setState({ ...state, editLine: false })}
        >
          <Box>
            {state.editLine &&          
              <GenCalUpd  calendarIds ={state.caleId?.calendarId} token = {token}  service = {state.service} deleteOnly= {'NO'} close ={ () => setState({ ...state, editLine: false})} />
            }          
          </Box>
        </SidePanel>
      </Box>
      <Box>
        <Typography sx={ subHeaderStyles }>{`Seuraohjelma / tilakalenteri :   ${filters.serviceProgramName}`}</Typography>
        <CalendarListFilters/>
        {/* <MouseOverPopOver popOverText = { popOverText } anchor = { anchorEl }/> */}
        {sheetArray.length > 0  &&         
          <Box>
            <Tooltip title = "Toimikunnan omat tapahtumat 'Toimikunta' Kaikki toimikunnat" placement="right-start" arrow>
              <FormControl sx={{ p:1}}>
                <InputLabel> Toimikunta</InputLabel>
                <Select
                  sx={{ minWidth: '100px'}}
                  labelId="selectToimikunta"
                  value = { state.newLineToimikunta}
                  name="toimikunta"
                  onChange={(e: any) => {
                    handleChange('newLineToimikunta', e.target.value)
                  }}
                  >      
                  {sheetArray[1].length > 0 &&
                  sheetArray[1].map( (tmk: any, i: number) =>
                    <MenuItem
                      key={i}
                      value={tmk[0]}
                    >
                      { tmk[0] }
                    </MenuItem>
                  )}          
                </Select>
              </FormControl>
            </Tooltip>
          </Box>
       
        }
        <Typography   sx={ subHeaderStyles }>{`Toimikuntien tapahtumat  ${state.newLineToimikunta}`}</Typography>
        <Table sx={{ width: '90%' }}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>  
            <Tooltip title = "Lisää tapahtuma tilakalenteriin" placement="top-start">
              <TableCell sx={{width:10}}>Kalenteriin</TableCell>  
            </Tooltip>
                  
              <TableCell sx={{width:10}}>{'Viikko'}</TableCell>
              <TableCell sx={{width:10}}>{'Pv'}</TableCell>
              <TableCell sx={{width:10}}>{'Päivä'}</TableCell>
              <TableCell sx={{width:10}}>{'Klo'}</TableCell>
              <TableCell sx={{width:10}}>{'Tapahtuma'}</TableCell>
              <TableCell sx={{width:10}}>{'Tilakalenteri'}</TableCell>
              <TableCell sx={{width:10}}>{'Tilakalenterissa?'}</TableCell>     
            </TableRow>
          </TableHead>         
          <TableBody>        
          {
            selectedLines.filter((fil: any) => { 
              return fil.subject !== '' && fil.subject !== ' ' && fil.subject})
            .map((til: any, i: number) => { 
              const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (                
                <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${i}_row16`}>
                  <TableCell 
                    // aria-owns = { open ? 'mouse-over-popover1' : undefined}
                    // aria-haspopup = "true"
                    // onMouseEnter={handlePopoverOpen}
                    // onMouseLeave={handlePopoverClose}
                    sx= {{ backgroundColor, borderTopRightRadius: 15, borderBottomRightRadius: 15, m:2, width: "1px"}}>
                    {til.Location && til.Location !== 'Juhlapyhä' && !calendarArrayState[til.linenumber] && til.Location !== '' && til.Location !== ' ' && caleList.indexOf(til.Location) !== -1 &&
                      <IconButton  onClick={() => lineClicked(til)}><EditIcon /></IconButton> 
                    }                    
                  </TableCell>                     
                  <TableCell sx={{ backgroundColor, textDecoration:"dotted", width: 10 }}> {til.Vko} </TableCell>
                  <TableCell sx={{ backgroundColor, textDecoration:"dotted", width: 10 }}> {til.Vkonpäivä} </TableCell>
                  <TableCell sx={{ backgroundColor, textDecoration:"dotted", width: 10 }}> {til.StartDate}</TableCell>
                  <TableCell sx={{ backgroundColor, textDecoration:"dotted", width: 10 }}> {til.StartTime}</TableCell>
                   
                    <TableCell sx={{ backgroundColor, textDecoration:"dotted", width: 10 }}> 
                      { til.Location === 'Keittiö' ? serviceState[til.linenumber -2].subject + ' ' + til.subject + ' ' + til.Selite : til.subject + ' ' + til.Selite}
                    </TableCell>      
                          


                  { caleList.indexOf(til.Location) === -1 && til.Location !== 'Juhlapyhä' && til.Location !== '' && til.Location !== ' ' ?
                
                  <TableCell sx={{ backgroundColor, textDecoration:"dotted", width: 10 , color : 'red'}}> {'KORJAA Location: ' + til.Location}</TableCell>
                  :
                  <TableCell sx={{ backgroundColor, textDecoration:"dotted", width: 10 }}> {til.Location}</TableCell>
                  }
                  <TableCell sx={{ backgroundColor, textDecoration:"dotted", width: 10 }}> {calendarArrayState[til.linenumber] === true ? 'Kyllä': 'Ei' } </TableCell>
                </TableRow>
              )
            })
          }
          </TableBody>   
        </Table>
         
        <Table sx={{ width: '90%' }}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            </TableRow>
          </TableHead>
          <TableBody>        
        
          </TableBody>  
        </Table>
      </Box>
    </Box>
  )
}

export default ServiceAnalyze;


// import React, { useEffect , useRef} from 'react' ;
// import { RootState } from "../../store/ConfigureStore";
// import { useSelector, useDispatch } from "react-redux";
import UpdateSpeaker from "../loadSpeakers/updateSpeaker";
import updateSpeakerColor from "./updateSpeakerColor";
import updateSpeakerCalendar from "../speakers/updateSpeakerCalendar";
import { startEditServiceProgram } from "../../actions/services";
import { editFilterData } from "../../actions/filters";

const  UpdateSpeakerToProgram = async (fromSpeaker: any, toSpeaker: any, serviceLine: any, authorities: any, filters: any, speakers: any, dispatch: any) => {
  const doDispatches = async () => {
    await dispatch(startEditServiceProgram(serviceLine.id, ({ sspeaker:toSpeaker, StartDate: serviceLine.StartDate, StartTime:serviceLine.StartTime ,
      EndDate: serviceLine.EndDate, EndTime:serviceLine.EndTime, subject:serviceLine.subject, questspeaker: fromSpeaker,
      linenumber:serviceLine.linenumber, Selite:serviceLine.Selite }), filters.servYear));
    await dispatch(editFilterData({
      text: '',
      serviceEdited: true,
      somethchanged: Math.random(),
      error: true, errorMessage: `${fromSpeaker} :n ${serviceLine.Vkonpäivä} Vuoro ${serviceLine.StartDate} ${serviceLine.StartTime}  siirretty ${toSpeaker} :lle`
    }));
  }

  const token = `bearer ${authorities.idToken}`;
  const sheetId = filters.sheetYears.find((sheet: any) => sheet[0] == filters.serviceYear);
  await UpdateSpeaker(token,
    serviceLine.linenumber,
    filters.serviceYear,
    sheetId[1],
    sheetId[2],
    sheetId[5],
    toSpeaker,
   sheetId[4],
    sheetId[4],
     authorities.email);

  const attendeear = speakers.filter((speaker: any) => speaker.speaker === toSpeaker);
  let speakerMail: any = {}
  if (attendeear.length === 0) {
    // this.setState(() => ({mail: 'matti.friman@helsinginrauhanyhdistys.fi'}))
    speakerMail = {mail: 'matti.friman@helsinginrauhanyhdistys.fi'}
  } else {
    // this.setState(() => ({mail:  attendeear[0].mail}))
    speakerMail = {mail:   attendeear[0].mail}
  }
  // const strikeThrough = 'xx';
  // await updateSpeakerColor( token, serviceLine.linenumber, sheetId[1],  sheetId[5], strikeThrough, sheetId[4],'');
  // const deleteonly:  any = false;
  // await updateSpeakerCalendar( token, filters, toSpeaker, serviceLine.StartDate, serviceLine.StartTime, serviceLine.EndDate, serviceLine.EndTime,
  // serviceLine.subject, serviceLine.selite, fromSpeaker, serviceLine.location,speakerMail.mail, serviceLine, deleteonly);
  try {
     await doDispatches(); 
     return 'ok'
  }catch(error) {
    return error
  }
}
export default UpdateSpeakerToProgram
 
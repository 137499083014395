/* eslint-disable no-extra-semi */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import UpdateSpeaker from '../loadSpeakers/updateSpeaker';
import updateSpeakerColor from './updateSpeakerColor';
import updateSpeakerCalendar from '../speakers/updateSpeakerCalendar';
import {  FormControl, Box, Select, Switch, InputLabel, Button, MenuItem, TextField, Typography, FormLabel, RadioGroup , FormControlLabel, Radio} from '@mui/material';
import 'react-dates/lib/css/_datepicker.css'
import { subHeaderStyles } from '../../styles/themeStylesSx';
import SidePanelSmall from '../sidepanel/SidePanelSmall';
import SpeakersLeaveList from '../speakers/SpeakersLeaveList';
import selectServices from '../../selectors/services';

import { serviceEdited, editFilterData } from '../../actions/filters';
import { startEditServiceProgram} from '../../actions/services';

const inputStyle = {
  marginRight: '8px',
  marginTop: '8px',
  marginBottom: '8px',
  width:'95%'
}

export  class ServiceForm extends React.Component<any, any> {
  constructor(props: any){
    super(props)
    this.state= {
      services2 : props.services2,
      filters2: props.filters2,
      othersTurns: ['','','','',''],
      viewturn:  [ ] ,
      selected: '',
      speaker: props.service ? props.service.sspeaker : '',
      id: props.service ? props.service.id : '',
      prevspeaker: props.service ? props.service.sspeaker : '',
      startdate: props.service ? moment(props.service.StartDate,'D.M.YYYY').format('D.M.YYYY') : moment(),
      starttime: props.service ? moment(props.service.StartTime,'HH.mm').format('HH.mm') : ('00.00'),
      enddate: props.service ? moment(props.service.EndDate,'D.M.YYYY').format('D.M.YYYY') : moment(),
      endtime: props.service ? moment(props.service.EndTime,'HH.mm').format('HH.mm') : ('24.00'),
      subject: props.service ? props.service.subject : '',
      type:  props.service ? props.service.type : '',
      selite: props.service ? props.service.Selite : '',
      linenumber: props.service ? props.service.linenumber : '',
      dayArray1:props.dayArray1,
      questspeaker: '',
      mail: ' ',
      location:props.service ? props.service.Location : '',
      serviceFocused : false,
      error: '',
      speakers: props.speakers,
      filters:props.filters,
      token : `bearer ${props.auth.idToken}`,
      servYear :props.filters.serviceYear,
      fromwhere : 'oma',
      selspeaker : '',
      selselite: '',
      finalSelite:'',
      updates:'',
      font:{color:'rgb(255,0,0)'},
      openSpeaker: false,
      leaveArray:[],
      typeArray:[],
      viewspeaker:'',
      confirm: false,
    }
  }

  handleConfirm =(e:any) => {
    const value = e.target.checked
    this.setState(() => ({ confirm: value }))
    if (!value) {
      this.setState(() => ({selected: ''}))
    }

  }

  onPaluu = (even: any) => {
    this.props.dispatch(serviceEdited(true));
  };
  
  handleChange = (event: any) => {
    const apu = event.target.value;
    this.setState(() => ({ fromwhere: apu }));
    this.setState(() => ({selspeaker : ''}))
    this.setState(() => ({questspeaker : ''}))
    this.setState(() => ({selselite : ''}))
    if (apu==='selite') {this.setState(() => ({selspeaker: this.state.speaker}))}
  };
  onChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(() => ({ [name]: value }));
  }
  onSelLeave = (e: any) => {
    // const name = e.target.name;
    const value = e.target.value;
    const leaveSpeaker = this.state.speakers.find((spe: any) => spe.speaker === value);
    this.setState(() => ({ leaveArray: leaveSpeaker.datesArrayFinal }));
    this.setState(() => ({ openSpeaker: true }));
    this.setState(() => ({typeArray: leaveSpeaker.typesArray}))
    this.setState(() => ({ leaveSpeaker: leaveSpeaker.speaker }));
  }
  onSpeakerChange = ( e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    var found = this.state.speakers
      .find((spname: any) => spname.speaker === value);

    let ok1 = true;
    if (found.typesArray.includes(this.state.type))
      ok1 = window.confirm ('Puhuja ei halua tätä tyyppiä vuoroja!. Ok?');

    var found1 = found.datesArrayFinal
      .findIndex((da: any) => {
        return moment(this.state.startdate,'D.M.YYYY').isBetween(moment(da.apvm) ,moment(da.ppvm),'day')  ||
          moment(this.state.startdate,'D.M.YYYY').isSame(moment(da.apvm),'day')  ||
          moment(this.state.startdate,'D.M.YYYY').isSame(moment(da.ppvm,'day'))
      });
    let ok = true;
    if (found1 !== -1) {
      ok = window.confirm ('Olet varaamassa vuoroa puhujan loma-ajalle. Ok?')
      // if (ok )
      //   this.setState(() => ({ [name]: value }));
    }
    if (ok && ok1) {
      this.setState(() => ({ [name]: value }))

      const services2 =  selectServices(this.state.services2, {text: value, sortBy: ' ', startDate: this.state.filters2.startDate, endDate: this.state.filters2.endDate});

      const toview = services2.filter((fi: any) => {return fi.strike !== 'y'}).map((view: any) => {
 
        return ([
          view.linenumber,
          view.StartDate,
          view.StartTime,
          view.subject,
          view.Selite,
          view.EndDate,
          view.EndTime
        ])
      })
      this.setState(() => (  {viewturn: toview}) );
      this.setState(() => ({ selected: ''}));

    };
  }
  onFocusChange = ({focused}: any) => {
    this.setState(() => ({ calendarFocused:    focused   }))
  }
  onTurnSwitch = (e: any) => {
    const serv2 = e.target.value;
    const selected = this.state.viewturn.find((vi: any) => {
      return vi[0] === serv2[0];
    });

    this.setState(() => ({selected: selected }))
  }
  onSaveChange = async (e: any) => {
    e.preventDefault();
    if (this.state.fromwhere === 'vieras') {
      this.setState(() => ({selspeaker: this.state.questspeaker}))
      this.setState(() => ({ questspeaker:' '}));
    }
    if (!this.state.questspeaker) {
      this.setState(() => ({ questspeaker:' '}));
    }

    this.setState(() => ({error:  ''}))
    let newSelite = '';
    let newValue = '';
    let columnStart = '';
    let columnEnd = ''

    if (this.state.fromwhere === 'oma') {
      newSelite = this.state.selite;
      newValue = this.state.selspeaker;
      columnStart = this.props.filters.columnSpeaker;
      columnEnd= this.props.filters.columnSpeaker;
    }

    if (this.state.fromwhere === 'vieras') {
      newSelite = this.state.selite;
      newValue = this.state.questspeaker;
      columnStart = this.props.filters.columnSpeaker;
      columnEnd= this.props.filters.columnSpeaker;
    }
    if (this.state.fromwhere === 'selite') {
      columnStart = this.props.filters.columnAddInfo;
      newValue = this.state.selselite;
      columnEnd= this.props.filters.columnAddInfo;
      newSelite = this.state.selselite;
    }

    await UpdateSpeaker(this.state.token,
      this.state.linenumber,
      this.state.servYear,
      this.props.filters.sheetId,
      this.props.filters.range,
      this.props.filters.subSheet,
       newValue,
       columnStart,
       columnEnd,
       this.props.auth.email);

    const attendeear = this.state.speakers.filter((speaker: any) => speaker.speaker === this.state.selspeaker);
    let speakerMail: any = {}
    if (attendeear.length === 0) {
      this.setState(() => ({mail: 'matti.friman@helsinginrauhanyhdistys.fi'}))
      speakerMail = {mail: 'matti.friman@helsinginrauhanyhdistys.fi'}
    } else {
      this.setState(() => ({mail:  attendeear[0].mail}))
      speakerMail = {mail:   attendeear[0].mail}
    }
    const strikeThrough = 'xx';
    await updateSpeakerColor(this.state.token,this.state.linenumber,this.props.filters.sheetId, this.props.filters.subSheet, strikeThrough,columnStart,'')
    const deleteonly:  any = false;
    if (this.state.fromwhere === 'oma')
      await updateSpeakerCalendar(this.state.token,this.props.filters,this.state.selspeaker,this.state.startdate, this.state.starttime,this.state.enddate,this.state.endtime,
    this.state.subject,this.state.selite,this.state.prevspeaker, this.state.location,speakerMail.mail, this.props.service, deleteonly);
    await this.props.dispatch(startEditServiceProgram(this.props.service.id, ({ sspeaker:this.state.selspeaker, StartDate: this.state.startdate, StartTime:this.state.starttime ,
      EndDate: this.state.enddate, EndTime:this.state.endtime, subject:this.state.subject, questspeaker:this.state.questspeaker,
      linenumber:this.state.linenumber, Selite: newSelite  }), this.state.servYear));
    await this.props.dispatch(editFilterData({
      text: '',
      serviceEdited: true,
      somethchanged: Math.random(),
    }));

    if (this.state.confirm && this.state.selected[0] !== '') {
      const spe = this.state.services2.find((se: any) => {
        return se.linenumber === this.state.selected[0];
      });
      await UpdateSpeaker(this.state.token,this.state.selected[0], this.state.servYear, this.props.filters.sheetId, this.props.filters.range,
        this.props.filters.subSheet, this.state.speaker, columnStart, columnEnd, this.props.auth.email)
      const attendeear = this.state.speakers.filter((speaker: any) => speaker.speaker === this.state.speaker);

      let speakerMail: any = {}
      if (attendeear.length === 0) {
        this.setState(() => ({mail: 'matti.friman@helsinginrauhanyhdistys.fi'}))
        speakerMail = {mail: 'matti.friman@helsinginrauhanyhdistys.fi'}
      } else {
        this.setState(() => ({mail:  attendeear[0].mail}))
        speakerMail = {mail:   attendeear[0].mail}
      }
      const strikeThrough = 'xx';
      await updateSpeakerColor(this.state.token,this.state.selected[0],this.props.filters.sheetId, this.props.filters.subSheet, strikeThrough, columnStart,'');
      const deleteonly: any = false;
      if (this.state.speaker !=='') {
        await updateSpeakerCalendar(this.state.token,this.props.filters,this.state.speaker,this.state.selected[1], this.state.selected[2] ,this.state.selected[5],this.state.selected[6],
         this.state.selected[3],this.state.selected[4],newValue, this.state.location,speakerMail.mail, spe, deleteonly);
      }
      
      await this.props.dispatch(startEditServiceProgram(spe.id, ({ sspeaker:this.state.speaker, StartDate: this.state.selected[1], StartTime:this.state.selected[2] ,
        EndDate: this.state.selected[5], EndTime:this.state.selected[6], subject:spe.subject, questspeaker:this.state.questspeaker,
        linenumber: spe.linenumber, Selite: spe.Selite  }), this.state.servYear));

      await this.props.dispatch(editFilterData({
        text: '',
        serviceEdited: true,
        somethchanged: Math.random(),
      }));
     
    }

    this.setState(() => ({confirm : false}))

  }

  render () {

    return (
      <Box sx={{ mt: 2 }}>
        <SidePanelSmall
          open={ this.state.openSpeaker === true }
          close={ () => this.setState({ openSpeaker: false })}
        >
        <SpeakersLeaveList speaker= {this.state.leaveSpeaker} leaveArray= {this.state.leaveArray } typeArray= {this.state.typeArray} close ={ () => this.setState({ openSpeaker: false })} />

      </SidePanelSmall>
      <Typography sx={ subHeaderStyles }>{'Päivitä puhujan tiedot / tilaisuuden tarkennus'}</Typography>
      {this.state.error && <p> {this.state.error}</p>}
      <Box>
        {/* <form onSubmit = {this.onSubmit}> */}
        <TextField
          sx={{ mt: 1 , width: 150 }}
          label="Speaker"
          autoFocus
          name="speaker"
          variant="outlined"
          value={this.state.speaker}
          //onChange={this.onChange}
        />
        <TextField
          sx={{ mt: 1, width: 110 }}
          label="Pvm"
          name="startdate"
          variant="outlined"
          value={this.state.startdate}
          //onChange={this.onChange}
        />
        <TextField
          sx={{ mt: 1, width: 80 }}
          label="Aika"
          name="starttime"
          variant="outlined"
          value={this.state.starttime}
          // onChange={this.onChange}
        />
        <TextField
          sx={{ mt: 1, width: 150 }}
          label="Subject"
          name="subject"
          variant="outlined"
          value={this.state.subject}
          //onChange={this.onChange}
        />
        <TextField
          sx={{ mt: 1 }}
          label="Selite"
          name="selite"
          variant="outlined"
          value={this.state.selite}
          //onChange={this.onChange}
        />
        <Box sx={{ mt: 1 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend" >Puhujan tai selitteen muutos</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={this.state.fromwhere} onChange={this.handleChange}>
              <FormControlLabel value="oma" style= {{padding:0, margin:0}} control={<Radio />} label="Oma" />
              <FormControlLabel value="vieras" style= {{padding:0, margin:0}} control={<Radio />} label="Vieras" />
              <FormControlLabel value="selite" style= {{padding:0, margin:0}} control={<Radio />} label="Seliteteksti tilaisuudelle" />
            </RadioGroup>
          </FormControl>
        </Box>
        {
        this.state.fromwhere === 'oma' ?
          <Box display="flex" sx={{ direction: 'column', justifyContent:  "space-between"}}>
            <Box display= "inline"  sx = {{ width: '45%'}}>
              <Typography variant="h5">{'Valitse'}</Typography>
              <Typography> </Typography>

              <FormControl  fullWidth = {true}>
                <InputLabel>Valitse Puhuja</InputLabel>
                <Select
                  labelId="selectSpeaker"
                  value={this.state.selspeaker}
                  name="selspeaker"
                  onChange={this.onSpeakerChange}
                >
                  {
                    this.state.speakers.map((speaker: any) =>
                      <MenuItem
                        key={speaker.linenumber}
                        value={speaker.speaker}
                      >
                        { speaker.speaker }
                      </MenuItem>
                    )
                  }
                </Select>

              </FormControl>



              <FormControlLabel
                control={<Switch checked={this.state.confirm} onChange={ this.handleConfirm} name="confirm" />}
                label="Vaihto"
              />
              {this.state.fromwhere === 'oma' &&  this.state.viewturn.length > 0 && this.state.confirm &&
                <Box sx = {{ width: '90%', mt: 2 }}>
                  <Box>
                    <Typography> </Typography>
                    <Typography variant="h5">{`Päivitä samalla puhujalle: ${this.state.speaker} joku puhujan ${this.state.selspeaker} vuoroista`}</Typography>
                    <Typography> </Typography>
                  </Box>


                <FormControl fullWidth = {true} >
                    <InputLabel>Valitse Vuoro</InputLabel>
                    <Select
                      labelId="selectTurn"
                      value= {this.state.selected}
                      name ="turns"
                      onChange= {this.onTurnSwitch}
                    >
                    {
                    this.state.confirm &&
                      this.state.viewturn.map( (turn: any) =>
                        <MenuItem
                          key={turn[0]}
                          value={turn}
                        >
                          {turn[0]}
                          {' '}
                          { turn[1]}
                          {' '}
                          { turn[2]}
                          {' - '}
                          { turn[5]}
                          {' '}
                          { turn[6]}
                          {' '}
                          { turn[3]}
                          {' '}
                          { turn[4]}
                          {' '}

                          {/* {turn.subject}
                          {' '}
                          {turn.selite} */}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              }
            </Box>
    
          </Box>
          :
          <Box>
            {
              this.state.fromwhere === 'vieras' ?
                <Box display="flex" sx={{ direction: 'column', justifyContent:  "space-between"}}>
                  <Box display= "inline"  sx = {{ width: '45%'}}>
                    <Typography variant="h5">{'Kirjoita vierailevan puhujan nimi'}</Typography>
                    <TextField
                      fullWidth={true}
                      style={ inputStyle }
                      label="Vieraileva puhuja"
                      name="questspeaker"
                      value={ this.state.questspeaker }
                      onChange={this.onChange}
                    />
                  </Box>
                </Box>
                :
                <Box>
                  <Typography variant="h5">{'Tilaisuuden tarkennus'}</Typography>
                  <TextField
                    style={ inputStyle }
                    label="Tilaisuuden selite"
                    name="selselite"
                    value={ this.state.selselite }
                    onChange={this.onChange}
                  />
                </Box>
            }
          </Box>
        }
        {
          this.state.fromwhere !== 'selite' ?
            <Box>
              <Box display= "flex"  sx={{ direction: 'column', justifyContent:  "space-between"}}>
              <Box sx= {{ width: '40%'}}>
                  {
                    (this.state.selspeaker === '' && this.state.questspeaker ==='') || this.props.service.strike ==='y' ?
                      <Button sx= {({mt: 2})} variant="contained" disabled>
                        disabled
                      </Button>
                      :
                      <Button sx= {({mt: 2})} color="primary" variant="outlined" onClick={this.onSaveChange}>Muuta puhuja</Button>
                  }
                </Box>
            </Box>
          </Box>
            :
          <Box sx= {({mt: 2})}>
            {
            this.state.selselite === '' || this.props.service.strike ==='y' ?
              <Button color="primary"   variant="outlined" disabled>
                disabled
              </Button>
              :
              <Button color="primary" variant="outlined" onClick={this.onSaveChange}>Muuta selite</Button>
          }
        </Box>
        }
        <Box sx= {({mt: 2})}>
            <Button variant="outlined" color={'primary'} onClick = {this.onPaluu} >Paluu</Button>
        </Box>
      </Box>

    </Box>

    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    authz:state.auth,
    services: selectServices(state.services, state.filters),
    filters2: state.filters,
  }
}
 export default connect(mapStateToProps) (ServiceForm)
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable linebreak-style */
//Make speakers visible
import moment from 'moment'
export default (sortedCalendars: any, { startDate, endDate}: any) => {
  return sortedCalendars.filter((calendar: any) => {
    const startWork: any = moment(startDate,'DD.MM.YYYY').format('D.M.YYYY')
    const endWork: any = moment(endDate,'DD.MM.YYYY').format('D.M.YYYY')
    const startDateMatch = moment( calendar.startDate,'D.M.YYYY').isSameOrAfter(moment(startWork,'DD.MM.YYYY'))
    const endDateMatch = moment( calendar.endDate,'D.M.YYYY').isSameOrBefore(moment(endWork,'DD.MM.YYYY'))
    
    return startDateMatch  && endDateMatch 
  })
}
/* eslint-disable linebreak-style */
/* eslint-disable semi */
import SubmitGetSheet from './SubmitGetSheet';
import { setSheetYears, editFilterData} from '../../actions/filters';
import moment from 'moment';

//const dispatch = useDispatch()
const submitGetSheetId = async (token: any, year: any, dispatch: any, doDispatch: any, filters: any ) => {
  const sheetId = process.env.REACT_APP_SHEET_ID;
  const range = 'Taulukko1!A1:I';

  const sheetdataRetr = async (token: any, sheetId: any, range: any ) => {     
    let sheetsData = await SubmitGetSheet(token, sheetId, range);

    let sheetYears: any = [];
    await sheetsData.sheets[0].data[0].rowData.forEach((row: any) => {
      sheetYears.push([
        row.values[0].formattedValue,
        row.values[1].formattedValue,
        row.values[2].formattedValue,
        row.values[3].formattedValue,
        row.values[4].formattedValue,
        row.values[5].formattedValue,
        row.values[6].formattedValue,
        row.values[7].formattedValue,
        row.values[8].formattedValue
      ])
    })
    
    // eslint-disable-next-line array-callback-return
    const sheetIdOfProgram = await sheetsData.sheets[0].data[0].rowData.filter((row: any) => {
      // eslint-disable-next-line eqeqeq
      if (row.values[0].formattedValue == year) return row
    });

    if (sheetIdOfProgram.length > 0) {
      const sheetData = ({
        serviceYear:year,
        sheetId: sheetIdOfProgram[0].values[1].formattedValue,
        range:sheetIdOfProgram[0].values[2].formattedValue,
        columnAddInfo:sheetIdOfProgram[0].values[3].formattedValue,
        columnSpeaker:sheetIdOfProgram[0].values[4].formattedValue,
        subSheet: sheetIdOfProgram[0].values[5].formattedValue,
        subjectColumn: sheetIdOfProgram[0].values[6].formattedValue,
        speakerSubColumn: sheetIdOfProgram[0].values[6].formattedValue,
        speakerSubDayCol1: sheetIdOfProgram[0].values[7].formattedValue,
        speakerSubDayCol2: sheetIdOfProgram[0].values[8].formattedValue,
        sheetYears: doDispatch && sheetYears
      })
      // if (doDispatch) {
      //   await dispatch(setSheetYears(sheetYears));
      // }
      return sheetData;
    }
    else {
      const sheetData =
        ({
          serviceYear: '',
          sheetId: '',
          range: '',
          columnAddInfo: '',
          columnSpeaker: '',
          subSheet: '',
          subjectColumn: '',
          speakerSubColumn: '',
          speakerSubDayCol1: '',
          speakerSubDayCol2: '',
          sheetYears:[],
          error: true
        })
      console.error('parametristossa vikaavikaa', sheetId, range);
      dispatch(editFilterData({error: true, errorMessage: 'Parametristo ei vielä valmis kyselyvuodelle'}))
      return sheetData
    }
  }
  
  if (filters.sheetYears && filters.sheetYears.length > 0) {
      const sheetIdOfProgram = filters.sheetYears.filter((row: any) => {
        return row[0] == year;

      })

    if (sheetIdOfProgram.length > 0) {
      const sheetData = ({
        serviceYear:year,
        sheetId: sheetIdOfProgram[0][1],
        range:sheetIdOfProgram[0][2],
        columnAddInfo:sheetIdOfProgram[0][3],
        columnSpeaker:sheetIdOfProgram[0][4],
        subSheet: sheetIdOfProgram[0][5],
        subjectColumn: sheetIdOfProgram[0][6],
        speakerSubColumn: sheetIdOfProgram[0][6],
        speakerSubDayCol1: sheetIdOfProgram[0][7],
        speakerSubDayCol2: sheetIdOfProgram[0][8],
      });
      return sheetData;
    }
    else {
      const sheetData =
        ({
          serviceYear: '',
          sheetId: '',
          range: '',
          columnAddInfo: '',
          columnSpeaker: '',
          subSheet: '',
          subjectColumn: '',
          speakerSubColumn: '',
          speakerSubDayCol1: '',
          speakerSubDayCol2: '',
          sheetYears:[],
          error: true
        })
        console.error('parametristossa vikaavikaa', sheetId, range);
        dispatch(editFilterData({error: true, errorMessage: 'Parametristo ei vielä valmis kyselyvuodelle'}))
        return sheetData
    }

  }
  else {
    const sheetDataStart = await sheetdataRetr (token, sheetId, range); 
    return sheetDataStart;
  }

}
export default(submitGetSheetId);
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect}  from 'react';
import { Button,  Table, TableBody, TableRow, TableCell, TableHead,  Box, CircularProgress, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {RootState} from '../../store/ConfigureStore';
import { useNavigate } from "react-router-dom";
import { colors } from '../../styles/themeStylesSx';
import { setLoading , setCalendarIds} from '../../actions/filters';
import submitGenCalRead from '../calendars/submitGenCalRead';
import SidePanel from '../sidepanel/SidePanel';
import submitGetCalendarIdArr from '../calendars/submitGetCalendarIdArr';
import { editFilterData } from '../../actions/filters';
import AddSingleLine from '../service/AddSingleLine';
import { StrikeSingleLine } from '../service/StrikeSingleLine';
import { Capitalize } from '../genUtilities/Capitalize';

type StateType = {
  checked?: boolean;
  openStrike?: boolean;
  id?: string;
  openSpeaker?: boolean;
};
let errors: string [] = [];
const CheckServiceProgram = () => {
  const dispatch = useDispatch();
  const [ holidays, setHolidays ] = useState([]);
  const [sortedCal, setSortedCalendar] = useState<any>([]);
  const [lineInd, setLineInd] = useState<number>(0);
  const [addLine, setAddLine] = useState(false);
  const authorit: any = useSelector((state: RootState) => state.auth)
  const [listCalendar,setListcalendar] =useState<any>();
  const [lineAdded, setLineAdded] = useState(false)
  const filters: any = useSelector((state: RootState) => state.filters);
  const services: any = useSelector((state: RootState) => state.services);
  const [state, setState] = useState<StateType>({ checked: false });
  const [ stateLine, setStateLine] = useState<any> ([false]);
  const [checkProgram, setCheckProgram] = useState(false);
  const token = `bearer ${authorit.idToken }`;
  const [listHolidays, setListHolidays]  = useState(false);
  const [loppiainenNextYear , setLoppiainenNextYear] = useState('');
  let variantx:  "text" | "outlined" | "contained" | undefined = 'outlined';
  let variantx1: "text" | "outlined" | "contained" | undefined = 'outlined';

  let navigate = useNavigate();
  const calendarList = [
    'services', 'Hry tilat', 'Autotalli', 'Juhlapyhät', 'Kahvio', 'Keittiö', 
    'Yläkerran kokoushuone', 'Kerhohuone', 'Arkisto', 'Leivintupa', 'Sali', 'Sauna', 'Toimisto'
  ];
 
  useEffect(() => {
    const createServiceHeaders = (searchSubj: any, searchDay: any) => {
      const serviceInProgram = services.filter((ser: any) => {
        return ser.subject === searchSubj && ser.strike !== 'y' && ser.Location === 'Juhlapyhä'
      });
      let isornot = serviceInProgram.length > 0 ? true: false;
      let serviceSpec: any = {};
      let wrongDay = '';
      if ( serviceInProgram.length > 0  &&  (moment(serviceInProgram[0].StartDate,'D.M.YYYY').unix() !== moment(searchDay,'YYYY-MM-DD').unix())) {
        wrongDay = serviceInProgram[0].StartDate;
        isornot = false;
      }
      serviceSpec = ({
        sortField :  moment(searchDay, 'YYYY-MM-DD').unix(),
        startDate:   moment(searchDay ,'YYYY-MM-DD').format('D.M.YYYY'),
        startTime:   moment(searchDay ,'YYYY-MM-DD').format('HH.mm'),
        endDate:     moment(searchDay ,'YYYY-MM-DD').add(1,'d').format('D.M.YYYY'),
        endTime :    moment(searchDay ,'YYYY-MM-DD').add(2,'d').format('HH.mm'),
        dayOfWeek:   Capitalize(moment(searchDay ,'YYYY-MM-DD').format('dd')),
        servicePgmDay: wrongDay,
        summary:    searchSubj,
        Subject: searchSubj,
        linenumber: wrongDay ==='' ? '': serviceInProgram[0].linenumber, 
        serviceId: '',
        id: '',
        type: '',
        location:   'Juhlapyhä',
        creator:   '',
        isInProgram: isornot ? 0: -1
      });
      return serviceSpec;
    }
    const handleHolidays = async (holidaysArray: any) => {
      const finnishHol = services.filter((ss: any) => {
          return ss.strike !=='y' ;
        });  
      const finnishHolidays = finnishHol.filter((serv: any) => {
        return holidaysArray.some((holiday: any) => { 
          return ( holiday.summary === serv.subject && serv.Location === 'Juhlapyhä' && moment(holiday.start.date,'YYYY-MM-DD').year() === moment( serv.StartDate,'D.M.YYYY').year()) ;
        })
      });
      let workArray: any = []
      finnishHolidays.forEach((holi: any) => {
        const calDays1 =  holidaysArray.filter(((holline: any) => {
          return ( 
            holline.summary ===  holi.subject &&
            moment(holline.start.date,'YYYY-MM-DD').year() === moment( holi.StartDate,'D.M.YYYY').year()
            );
        }
        ));
        for (const calDays of calDays1) {     
          const work =  ({           
            sortField :  moment(calDays.start.date).unix(),
            startDate:   moment(calDays.start.date,'YYYY-MM-DD').format('D.M.YYYY'),
            startTime:    moment(calDays.start.date,'YYYY-MM-DD').format('HH.mm'),
            endDate:      moment(calDays.end.date,'YYYY-MM-DD').format('D.M.YYYY'),
            endTime :     moment(calDays.end.date,'YYYY-MM-DD').format('HH.mm'),
            dayOfWeek:   Capitalize(moment(calDays.start.date,'YYYY-MM-DD').format('dd')),
            servicePgmDay: moment(calDays.start.date,'YYYY-MM-DD').unix() === moment( holi.StartDate,'D.M.YYYY').unix() &&
                          moment(calDays.start.date,'YYYY-MM-DD').year() === moment( holi.StartDate,'D.M.YYYY').year()
            ?  '' : holi.StartDate, 
            summary:    calDays.summary,
            linenumber: holi.linenumber, 
            Subject: calDays.summary,
            serviceId: holi.serviceId,
            id: calDays.id,
            location:   'Juhlapyhä',
            creator:   '',
            type: '',
            isInProgram: moment(calDays.start.date,'YYYY.MM.DD').unix() === moment(holi.StartDate,'D.M.YYYY').unix() ? 0 : -1 
          });
        workArray.push(work)
        }
      }); 
      const exfinnishHolidays = holidaysArray.filter((holiday: any) => {
        return !finnishHolidays.some((serv: any) => { 
          return (holiday.summary === serv.subject && serv.Location === 'Juhlapyhä' &&  moment(holiday.start.date,'YYYY-MM-DD').year() === moment( serv.StartDate,'D.M.YYYY').year()  ) 
        })
      });
    
      const caleList: any = exfinnishHolidays.map((cal: any) => {
        const formatDateTime = (dateTime: string | undefined, format: string) =>
          dateTime ? moment(dateTime, 'YYYY-MM-DDTHH:mm:ssZ').format(format) : '';

        const formatDate = (date: string | undefined, format: string) =>
          date ? moment(date, 'YYYY-MM-DD').format(format) : '';

        const startDateTime = cal.start.dateTime || cal.start.date;
        const endDateTime = cal.end.dateTime || cal.end.date;

        return {
          sortField: moment(startDateTime).unix(),
          startDate: formatDateTime(cal.start.dateTime, 'D.M.YYYY') || formatDate(cal.start.date, 'D.M.YYYY'),
          startTime: formatDateTime(cal.start.dateTime, 'HH.mm') || formatDate(cal.start.date, 'HH.mm'),
          endDate: formatDateTime(cal.end.dateTime, 'D.M.YYYY') || formatDate(cal.end.date, 'D.M.YYYY'),
          endTime: formatDateTime(cal.end.dateTime, 'HH.mm') || formatDate(cal.end.date, 'HH.mm'),
          dayOfWeek: Capitalize(
            formatDateTime(cal.start.dateTime, 'dd') || formatDate(cal.start.date, 'dd')
          ),
          servicePgmDay: '',
          summary: cal.summary,
          Subject: cal.summary,
          serviceId: '',
          id: cal.id,
          type: '',
          location: 'Juhlapyhä',
          creator: '',
          isInProgram: -1,
        };
      });


      const midSummer = holidaysArray.find((row: any) => row.summary === 'Juhannuspäivä') 
      const summerService = moment(midSummer.start.date,'YYYY-MM-DD').add(6, 'w');
      const searchSubj = 'Kesäseurat';
      const searchDay = summerService;

      const serviceSpec = createServiceHeaders (searchSubj, searchDay);
      const autumnServices = moment(midSummer.start.date,'YYYY-MM-DD').add(15, 'w');
      const searchSubjAutumn = 'Syysseurat';
      const searchDayAutumn = autumnServices;
      const serviceSpecAutumn = createServiceHeaders (searchSubjAutumn, searchDayAutumn);      
      const bigServices = moment(midSummer.start.date,'YYYY-MM-DD').add(5, 'd');
      const searchSubjBig = 'Suviseurat';
      const searchDayBig = bigServices;

      const serviceSpecBig = createServiceHeaders (searchSubjBig, searchDayBig);
     
 
      const workDate = moment(filters.serviceYear, 'YYYY');
      const wintwrLeave = moment(moment(workDate).startOf('week')).week(8);
      const winterLeaveSubj  = 'Talviloma';
      const wintwerLeaveDay =  wintwrLeave;
      const serviceWinterLeave = createServiceHeaders (winterLeaveSubj, wintwerLeaveDay);
      const autumnLeave = moment(moment(workDate).startOf('week')).week(42);
         const autumnLeaveSubj  = 'Syysloma';
      const autumnLeaveDay =  autumnLeave;
      const serviceAutumnLeave = createServiceHeaders (autumnLeaveSubj, autumnLeaveDay);
      const array: any =  workArray.concat( caleList, serviceSpec, serviceSpecAutumn, serviceSpecBig, serviceWinterLeave, serviceAutumnLeave );
      const work1 = array.map((cal: any) =>  false);
      setStateLine(work1); 
      const sortedCalendar: any = array.sort(( a: any,b: any ) => {
        var x: any= a.sortField
        var y: any = b.sortField
        if (x < y) {return -1}
        if (x > y) {return 1}
        else return 0
      })
      .filter((x: any) => x)
      setSortedCalendar(sortedCalendar);
    }
    const readCal = async( token: any, workStart: any) => {
      const calendarDataArray1 = await submitGetCalendarIdArr(token);  
      dispatch( setCalendarIds(calendarDataArray1));
      const calId = calendarDataArray1.filter((cal: any) => cal.calendar === 'Juhlapyhät');
      const calendarRows = await submitGenCalRead(calId[0].calendarId, token, workStart,filters.endDate, dispatch);
      dispatch(setLoading(false));
      setHolidays(calendarRows);
      const loppiainenNY = calendarRows.filter((cr: any) => cr.summary === 'Loppiainen');
      let loppiainenDay: any = moment().format('YYYY-MM-DD');
      if (loppiainenNY.length === 2) {
        loppiainenDay = loppiainenNY[1].start.date;
      }else {
        loppiainenDay = moment(loppiainenNY[0].start.date).add(1,'y');
      }      
      setLoppiainenNextYear(moment(loppiainenDay,'YYYY-MM-DD').format('D.M.YYYY'));
      await handleHolidays(calendarRows);
      return calendarRows;
    }  
    if (filters.sheetYears.length === 0) {
      navigate("/seuraohjelma");
    }else {
      dispatch(setLoading(true));
      const workStart = moment(filters.startDate).startOf('year');
      dispatch(editFilterData({startDate:workStart}));
      readCal( token, workStart);
    }
  }, [filters.serviceYear])

  if (filters.loading) return  <div> Waiting ... <CircularProgress/></div>
  if (!filters.read) {
    return (<div> <br />. Ei vaadittuja oikeuksia ListServiceProgramPage</div>)
  }
  if (!authorit.idToken) return <div>Kirjaudu järjestelmään</div>

  if (!checkProgram)
    { variantx = "outlined"}
  else
    {variantx = "contained"}

    if (!listHolidays)
    { variantx1 = "outlined"}
  else
    {variantx1 = "contained"}

  const handleHolidaysList = () => {
    setListHolidays(!listHolidays);
  }
  const handleClickCal = async(i: number, line: any) => {
    console.log('lilili', line)
    if (line.servicePgmDay !== '') {     
      await StrikeSingleLine(line, token, filters.currentSheet.sheetId, filters.currentSheet.subSheet, dispatch, filters.serviceYear )
    }    
    const param = ({  
      newLineExcluded: '',
      newLineStartDate: sortedCal[i].startDate,
      newLineStartTime: sortedCal[i].startTime,
      newLineSubject: sortedCal[i].summary,
      newLineEndDate: sortedCal[i].endDate,
      newLineEndTime: sortedCal[i].endTime, 
      newLineVko:    moment(sortedCal[i].startDate, "D.M.YYYY").isoWeek(), 
      newLineVkonpäivä :  Capitalize(moment(sortedCal[i].startDate, 'D.M.YYYY').format('dd')),
      newLineToimikunta : 'Johtokunta',
      newLineCreator: '',
      newLineDayOfWeek: line.dayOfWeek,
      newLineLocation : 'Juhlapyhä',
    });
    dispatch(editFilterData(param));   
    setLineInd(i);
    setAddLine(true);
    setLineAdded(false);
  }
  if (lineAdded) {
    setLineAdded(false);
    let work:any = [...sortedCal];
    work[lineInd].isInProgram = lineInd;   
    setSortedCalendar(work);  
  }

  const inclSubj = ['messu', 'jämsä', 'suviseurat', 'espoo', 'vantaa', 'koulutus', 'retki', 'alueellinen', 'juhannusseurat', 'valmistelu' ];
  const inclLisätieto = ['vanha kirkko'];
  const inclTypes = ['ei seuroja','ei seuroja','espoo', 'vantaa'];

  const HandleCheckBoxChange = () => {
    dispatch(setLoading(true));
    setCheckProgram(!checkProgram)
    errors = [];
    if (!checkProgram){      
      const haveToBeType = (subject: any) => {
        const inclTypes = ['seurat','messu','oulunk', 'alustus', 'peli', 'raamattutunti','nuortenilta','keskustelu','kirkko', 'hartaus','myyjäiset', 'itsenäisyys', 
        'markkina','kevätjuhla','mustasaari','lauluilta','joulumyyjäiset','joulujuhla','joululauluilta', 'rippikoulun talvipäivä', 'nuorten aikuisten', 'kesähelsinkiläisten', 'lapsi- ja nuorisotyön' ];
        return inclTypes.some((so:any) => {return subject.toLowerCase().includes(so)} )
      };
      const besides = (Selite: any, subject: any) => {
        let returnable =  inclTypes.some((so:any) => {return Selite.toLowerCase().includes(so)} )
        if (!returnable ){
          returnable = inclSubj.some((so:any) => {return subject.toLowerCase().includes(so)} )
        }
        
        return returnable
      };
      const besidesLocation = (subject: any, selite: any ) => {
      
        let returneable =  inclSubj.some((sub:any) => {return subject.toLowerCase().includes(sub)} )
        if (! returneable)
          returneable =  inclLisätieto.some((lis:any) => {return selite.toLowerCase().includes(lis)} )
        return returneable
      };
      if (services.length === 0  ){
        errors.push('Lataa Suraohjelma ensinnä')
      }
      else {
        const workYear: number = moment(services[10].StartDate,'D.M.YYYY').year();
        if (workYear != filters.serviceYear) {
            errors.push(`Lataa oikea seuraohjelma ensinnä. Nyt ladattu vuoden ${workYear} ohjalme`)
        }
      }
      if (errors.length === 0) {
        const startDateArray : any = [];
        services.forEach((service: any) => {
          if (service.Kassaryhmä !== 'Kassaryhmä') {
            if ( service.Vko.trim() !== '' ) {
              const workwork = moment(service.StartDate, 'D.M.YYYY').format('YYYY-MM-DD') + 'T' +
                moment(service.StartTime,'HH:mm').format('HH:mm') + ':00Z';
              const start: any = moment(workwork, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DDTHH:mm:ssZ');
              const format_start: any = 'YYYY-MM-DDTHH:mm:ssZ';
              if (!moment(start, format_start).isValid()) {
                errors.push(service.linenumber +' Virheellinen tilaisuuden alkaminen '  + service.subject + ' ' + service.Selite + ' ' + service.StartDate + ' ' + service.EndDate)
              }else {
                if (!moment(service.StartDate,'D.M.YYYY').isSame(moment(loppiainenNextYear,'D.M.YYYY')))
                if (services.findIndex((findDay: any, iii: any) => {
                  return  moment(findDay.StartDate, 'D.M.YYYY').isSame(moment(service.StartDate, 'D.M.YYYY').add(1,'d'))}) ===  -1) {
                    errors.push(service.linenumber + 'Tämän rivin jälkeen ei riviä päivälle ' + moment(start, format_start).add(1,'d').format('D.M.YYYY'))
                  }
              }
              if (startDateArray.findIndex((date: any) =>
                moment( start,format_start).unix() === moment(date,format_start).unix()  && service.type !== '5' && service.type !== '' && service.type !== ' '  ) !== -1) {
                errors.push(service.linenumber + ' Sama pvm ja aika usealla rivillä ' + service.subject + + ' ' + service.Selite + ' ' + service.StartDate + ' ' + service.EndDate)
              }
              startDateArray.push(moment(start, format_start).format('YYYY-MM-DDTHH:mm:ssZ'));
              const workwork1 = moment(service.EndDate, 'D.M.YYYY').format('YYYY-MM-DD') + 'T' +
              moment(service.EndTime,'HH:mm').format('HH:mm') + ':00Z';
              const end = moment(workwork1);
              const format_end = 'YYYY-MM-DDTHH:mm:ssZ';
              if (!moment(end, format_end).isValid()) {
                errors.push(`${service.linenumber} Virheellinen tilaisuuden päättyminen ${service.subject} ${service.Selite}${service.StartDate} ${service.EndDate}`)
              };
              if (moment(end).isSameOrBefore(moment(start))) {
                errors.push(service.linenumber +' Tarkista että päättymispäiv suurempi kuin alkaminen '  + service.subject + ' ' + service.Selite + ' ' + service.StartDate + ' ' + service.EndDate)
              };
              const workDate = moment(service.StartDate,'D.M.YYYY');
              const format_1 = 'YYYY-MM-DDTHH:mm:ssZ';
              const format_time = 'HH.mm'
              const workTime = moment(service.StartTime,'HH.mm');
              if (!moment(workTime, format_time).isValid()) {
                errors.push(service.linenumber +' Virheellinen alkamisaika '  + service.subject + ' ' + service.Selite + ' ' + service.StartDate + ' ' + service.EndDate)
              };
              if (!moment(workDate, format_1).isValid()) {
                errors.push(service.linenumber + ' Virheellinen alkamispäivämäärä ' + service.subject + ' ' + service.Selite + ' ' + service.StartDate + ' ' + service.EndDate)
              };
              const format_time1 = 'HH.mm'
              const workTime_1 = moment(service.EndTime,'HH.mm');
              if (!moment(workTime_1, format_time1).isValid()) {
                errors.push(service.linenumber +' Virheellinen päättymisaika '  + service.subject + ' ' + service.Selite + ' ' + service.StartDate + ' ' + service.EndDate)
              };
              const workDate1 = moment(service.EndDate,'D.M.YYYY');
              const format_2 = 'YYYY-MM-DDTHH:mm:ssZ';
              if (!moment(workDate1, format_2).isValid()) {
                errors.push(service.linenumber +' Virheellinen päättymispäivämäärä ' + service.subject + ' ' + service.Selite + ' ' + service.StartDate + ' ' + service.EndDate)
              };
              const arr: any = service.StartTime.split('.');
              if (arr.length !== 2) {
                errors.push(service.linenumber + ' Virheellinen Alkamisaika! ' + service.subject + ' ' + service.Selite + ' '
                + service.StartDate + ' ' + service.StarTime + ' ' + service.EndDate);
              } else
              {
                if (arr[0] < '00' && arr[0] > '24' ) {
                  errors.push(service.linenumber + ' Virheellinen Alkamisaika! HH virhe ' + service.subject + ' ' + service.Selite + ' '
                  + service.StartDate + ' ' + service.StarTime + ' ' + service.EndDate);
                }
                if (arr[1] < '00' && arr[1] > '59' ) {
                  errors.push(service.linenumber + ' Virheellinen Alkamisaika! mm virhe ' + service.subject + ' ' + service.Selite + ' '
                  + service.StartDate + ' ' + service.StarTime + ' ' + service.EndDate);
                }
              }
              const arr1: any = service.EndTime.split('.');
              if (arr1.length !== 2) {
                errors.push(`${service.linenumber} Virheellinen Päättymisaika! ${service.subject} ${service.Selit}e ${service.StartDate} ${service.StarTime} ${service.EndDate} ${service.EndTime}`)
              };
            }
            if (moment(service.StartDate,'D.M.YYYY').unix() > moment(service.EndDate,'D.M.YYYY').unix()) {
              errors.push(`${service.linenumber}  Apvm-Ppvm ei ok: ${service.StartDate} ${service.EndDate}`);
            }
            if (moment(service.StartTime,'HH.mm').unix() > moment(service.EndTime,'HH.mm').unix() && (moment(service.StartDate,'D.M.YYYY').unix() === moment(service.EndDate,'D.M.YYYY').unix())) {
              errors.push(`{service.linenumber} + ' Apvm klo -Ppvm klo ei ok:  ${service.StartDate} ${service.EndDate}`);
            }
            if (moment(service.StartDate,'D.M.YYYY').isBefore(`${filters.serviceYear}-01-01`) &&  !moment(service.StartDate,'D.M.YYYY').isAfter(`${filters.serviceYear-1}-12-28`)) {
              errors.push(`${service.linenumber} Alkamispäivä ei ole seuraohjelman vuodella ${service.linenumber} ${service.StartDate} ${service.EndDate}` )
            };
            if ( service.Vko.trim() === '' ) {
              if (service.StartDate.trim() !== '' ) {
                errors.push( `${service.linenumber} Lisää viikkonumero  ${service.subject} ${service.Selite} ${service.StartDate}  ${service.EndDate}`)
              }
            };
            if (service.type.trim()  ==='' ) {
              if (haveToBeType(service.subject) && !besides(service.Selite, service.subject)) errors.push( `${service.linenumber}  Type puuttuu  ${service.subject} ${service.Selite} ${service.StartDate} ${service.EndDate}` )
            }
            if (service.type.trim() !== '') {
              if (!haveToBeType(service.subject)) {
                errors.push(
                `${service.linenumber} Type turhalla rivillä ${service.StartDate} ${service.EndDate} ${service.subject} ${service.Selite}`
                ); 
              }
              const validTypes = ['0', '1', '2', '3', '4', '8', '10'];
              

              if (validTypes.includes(service.type) && !besidesLocation(service.subject, service.Selite)) {
                  if (service.Location.trim() === '') {
                      errors.push(
                          `${service.linenumber} Location puuttuu! ${service.StartDate} ${service.EndDate} ${service.subject} ${service.Selite}`
                      );
                  }
              }              
            };
            if (calendarList.findIndex((loc: string) => service.Location === loc) === -1 && service.Location.trim() !== '' && service.Location !== 'Juhlapyhä') {
              errors.push(  `${service.linenumber} Korjaa Location! ${service.StartDate} ${service.EndDate} ${service.subject} ${service.Selite} ${service.Location}`)
            }
            const isLocationEmpty = service.Location.trim() === '';
            if (isLocationEmpty && service.subject.toLowerCase() === 'kahvitus') {
              errors.push(
                `${service.linenumber} Location puuttuu! ${service.StartDate} ${service.EndDate} ${service.subject} ${service.Selite}`
              );
            }
            if (service.subject.trim() === '' && service.Selite.trim() !== ''  ) {
              errors.push (
                `${service.linenumber} Tarkista, että Subject on kuvattu ${service.StartDate} ${service.EndDate} ${service.subject} ${service.Selite}`
              )
            }

            if (filters.loading) return<div> Loading data <CircularProgress/></div>
          }
      
        });
      }
      setState({checked: true})
    }
    dispatch(setLoading(false));
  }
  return  (    
    <Box>
      <Box>
        <SidePanel
        open={ addLine === true }
        close={ () => setAddLine(false)}
        >
          <Box>
            {addLine &&  
              <AddSingleLine filters = {filters} close ={ () => {setAddLine(false)}} listing= {() =>{ setLineAdded(true)}} listCale = {()=> setListcalendar(true)} />
            }
          </Box>
        </SidePanel>      
      </Box>
       Seuraohjelman vuosi valitaan Seuraohjelma-sivulla!
        <Box  sx={{ display: 'flex', alignItems: 'center', p: 0 }}>
          {filters.admin &&
            <Box>
              <Box sx= {{p: 2}}>
                <Button variant = {variantx} size='small' color = "primary" sx={{ fontSize: '15px', width:150, height: 50, p: '5px'}}
                onClick ={HandleCheckBoxChange}>
                  Tarkista {filters.serviceYear}  seuraohjelma
                </Button>
              </Box>
            </Box>
          }
          {filters.admin &&
            <Box>
              <Box>
                <Button variant =  {variantx1} size = 'medium' color = "primary" sx={{ fontSize: '15px', width : 150, height: 50, p:'5px'}}
                onClick ={handleHolidaysList}>
                  {filters.serviceYear}  juhlapyhät
                </Button>
              </Box>
            </Box>
          }
        </Box>
        <Table>
          {errors.length > 0 && checkProgram &&
            <TableBody>
             {
              errors.length > 0 ?
              errors.map((err: string, i: number) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return(
                <TableRow sx = {{height : 20, " & td":{ border : 0}, color: 'secondary.main' }} key={`${i}_row1`}>
                  <TableCell sx = {{ backgroundColor }}> {err} </TableCell>
                </TableRow>
              )
              })
              :
              <div> (Kaikki kondiksessa)</div>
              }
            </TableBody>
          }
        </Table>
      <Box>
        <Box>
          {listHolidays &&
            <Table>
              <TableHead>
                <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>            
                  <TableCell>{'Pvm'}</TableCell>
                  <TableCell>{'Aika'}</TableCell>
                  <TableCell>{'Pv'}</TableCell>
                  <TableCell>{'Tilaisuus'}</TableCell>
                  <TableCell>{'Seuraohjelman apvm'}</TableCell>   
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedCal.filter((fil:any) => fil.sortField)
                  .map((line: any,i: any) =>{
                    const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                    return (              
                      <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${i}_row`}>                      
                        <TableCell padding="checkbox"                       
                        > 
                        {line.isInProgram < 0 &&          
                          <IconButton onClick={(e) =>handleClickCal(i,line)}><EditIcon/></IconButton>
                        }    
                        </TableCell>
                        <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.startDate } </TableCell>
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.startTime}  </TableCell>
                        {line.dayOfWeek.toLowerCase() !=='su' ?
                          <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.dayOfWeek}  </TableCell>
                          :
                          <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, color: colors.red}}>{ line.dayOfWeek}  </TableCell>
                          }
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.summary }  </TableCell>
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.servicePgmDay }  </TableCell>            
                      </TableRow>
                    )
                  })            
                }
              </TableBody>
            </Table>
          }
        </Box>
      </Box>
    </Box>
    )
}
export default CheckServiceProgram